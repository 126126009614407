import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import the validation component

const DeviceName: React.FC<{
    mode: 'new' | 'edit';
    DeviceNameInput: string;
    setDeviceNameInput: React.Dispatch<React.SetStateAction<string>>;
    initialize?: boolean;
}> = ({ mode, DeviceNameInput, setDeviceNameInput, initialize = false }) => {

    // Use the text validation hook
    const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setDeviceNameInput);
    const [isFocused, setIsFocused] = useState(false);

    // Handle focus to show validation state
    const handleFocus = () => {
        setIsFocused(true);
    };

    // Handle blur event
    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className="dds__input-text__container">
            <label htmlFor="DeviceName-field" className="dds__label">
                {mode === 'edit' ? 'Device Name' : ' Device Name'}
                <span style={{ color: 'red' }}> *</span>
            </label>
            <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                <input
                    type="text"
                    className="dds__input-text"
                    style={{
                        width: '100%',
                        borderRadius: '1.5rem',
                    }}
                    maxLength={100}
                    name="DeviceName-field"
                    id="DeviceName-field"
                    required
                    aria-labelledby="DeviceName-label"
                    onChange={(event) => validateInput(event.target.value)}  // Use validateInput from UseTextValidation
                    onBlur={handleBlur} // Add onBlur handler
                    onFocus={handleFocus} // Add onFocus handler
                    value={DeviceNameInput}
                />
                {/* Show validation messages based on focus and validity */}
                {isFocused && !isValid && (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                        Accepts a valid string. {errorMessage}
                    </span>
                )}
                {isFocused && isValid && DeviceNameInput && (
                    <span style={{ color: 'green', fontSize: '12px' }}>
                        Accepts a valid string (up to 30 characters).
                    </span>
                )}
            </div>
        </div>
    );
};

export default DeviceName;
