import { useUserInfo } from "../../../../store/auth/UserInfoContext";
import { getGreetingMessage } from "../../../../utils/greetingMessage";
import placeholderImage from "../../../../Assets/blank_user.svg";
import { useUserImageAPI } from "../useUserImageAPI";
import { TypeWriterEffect, IntuneInfo } from "../Styles";
import useFrictionlessIntuneAPI from "../useFrictionlessIntuneAPI";
import ReactTooltip from "react-tooltip";

const BannerLeftComponent = () => {
    const user = useUserInfo().getUserInfo();
    const greetingMessage = getGreetingMessage(user.userName);
    const { data: imageURL } = useUserImageAPI(user.badgeId);
    const { data } = useFrictionlessIntuneAPI();


    const intuneStatusToolTip = `
    <strong>Intune:</strong><br/>
     ${data?.device_data?.map((device, index) => (
        `<div key=${index}>
            ${device.model} - ${device.servicetag} - SHIR: ${device.isShir}
        </div>`
    )).join('')}
     `;

    return (
        <div
            className="dds__d-flex dds__align-items-center dds__text-white dds__my-3 dds__m-lg-0"
            style={{
                gap: "1rem",
            }}
        >
            <img
                width={100}
                height={100}
                src={imageURL ? imageURL : placeholderImage}
                alt={`Profile image of ${user.userName}`}
                style={{ borderRadius: "24px" }}
            />
            <div
                className="dds__d-flex dds__flex-column"
                style={{
                    // gap: "0.5rem",
                }}
            >
                <div className="dds__mb-2">
                    <div className="dds__d-block dds__d-xl-none">
                        <h4 className="dds__text-white">{greetingMessage},</h4>
                        <TypeWriterEffect>
                            <h5
                                className="dds__text-white dds__font-weight-normal dds__d-inline "
                            >
                                Welcome to TrackIT Dashboard
                            </h5>
                        </TypeWriterEffect>
                    </div>
                    <TypeWriterEffect className="dds__d-none dds__d-xl-block">
                        <h4 className="dds__mr-2 dds__d-inline dds__text-white ">
                            {greetingMessage},
                        </h4>
                        <h5
                            style={{
                                background: "#17319E",
                            }}
                            className=" dds__d-inline dds__text-white dds__font-weight-normal "
                        >
                            Welcome to TrackIT Dashboard
                        </h5>
                    </TypeWriterEffect>
                </div>
                <div
                    className="dds__d-none dds__d-xl-flex  dds__py-1 "
                    style={{
                        gap: "1rem",
                    }}
                >
                    {user.jobTitle && (
                        <span>
                            <i
                                className="dds__icon dds__icon--user-directory dds__mr-2"
                                aria-hidden="true"
                            />
                            {user.jobTitle}
                        </span>
                    )}
                    {user.email && <span>
                        <i
                            className="dds__icon dds__icon--mail dds__mr-2"
                            aria-hidden="true"
                        />
                        {user.email}
                    </span>}
                    {user.city && user.country && (
                        <span>
                            <i
                                className="dds__icon dds__icon--location dds__mr-2"
                                aria-hidden="true"
                            />
                            {user.city}, {user.country}
                        </span>
                    )}
                </div>
                {!data && <div style={{
                    height: "1.5rem",
                }}>
                </div>}
                {data && <div
                    className="dds__d-none dds__d-xl-flex "
                    style={{
                        gap: "1rem",
                    }}
                >
                    {data.frictionlessuser && (
                        <span>
                            <i
                                className="dds__icon dds__icon--accessibility dds__mr-2"
                                aria-hidden="true"
                            />
                            Frictionless User
                        </span>
                    )}
                    {data.intuneStatus &&
                        <IntuneInfo>
                            <span data-for="intunestatus_tip" data-tip={intuneStatusToolTip}>
                                <i
                                    className="dds__icon dds__icon--brand-windows dds__mr-2"
                                    aria-hidden="true"
                                />
                                Intune:{" "}
                                {data.device_data.map((device, index) => (
                                    index === 0 && (<span key={index}>
                                        {device.model} - {device.servicetag} - SHIR: {device.isShir}
                                        {/* {index < data.device_data.length - 1 && ", "} */}
                                        {index < data.device_data.length - 1 && "  "}
                                    </span>
                                    )
                                ))}
                                {data.device_data.length > 1 && <span style={{ textDecoration: "underline" }}>
                                    {" +"}{data.device_data.length - 1} more
                                </span>}
                            </span>
                            <ReactTooltip id="intunestatus_tip" html={true} />
                        </IntuneInfo>}
                </div>}
            </div>
        </div>
    );
};

export default BannerLeftComponent;
