import ClientTable from '../../../Common/ClientTable/ClientTable';
import DefaultCell from '../../../Table/ReusableTableCells/DefaultCell';
import ActionColumn from "./DataModel/ActionColumn/ActionColumn";
const ExpandComponent = ({ row, columns, expandProps }) => {

    console.log("rowobject", row)
    console.log(columns)
    const SubData = [
        {
            financialYear: row.values["financialYear"],
            processor: row.values["processor"],
            deviceName: row.values["deviceName"],
            deviceType: row.values["deviceType"],
            persona: row.values["persona"],
            // user_type: row.values["user_type"],
            memoryInGb: row.values["memoryInGb"],
            storage: row.values["storage"],
            graphics: row.values["graphics"],
            display: row.values["display"],
            thunderbolt: row.values["thunderbolt"],
            battery: row.values["battery"],
            connectivity: row.values["connectivity"],
            isexclusive: row.values["isexclusive"],
            actions: row.values["actions"],
        }
    ];
    const SubColumns = [
        {
            Header: () => null, // No header
            id: '__id',
            Cell: DefaultCell,
            disableResizing: true,
            width: 50,
        },

        { Header: 'Financial Year', accessor: 'financialYear' },
        { Header: 'Processor', accessor: 'processor' },
        { Header: 'Device Type', accessor: 'deviceType' },
        { Header: 'Device Name', accessor: 'deviceName' },
        // { Header: 'User Type', accessor: "user_type" },
        { Header: 'Persona', accessor: 'persona' },
        { Header: 'Memory', accessor: 'memoryInGb' },
        { Header: 'Graphics', accessor: 'graphics' },
        { Header: 'Storage', accessor: 'storage' },
        { Header: 'Display', accessor: 'display' },
        { Header: 'Thunderbolt', accessor: 'thunderbolt' },
        { Header: 'Battery', accessor: 'battery' },
        { Header: 'Connectivity', accessor: 'connectivity' },
        { Header: 'IsExclusive', accessor: 'isexclusive' },
        {
            Header: 'Actions', accessor: 'actions', width: 70,
            Cell: ActionColumn,
        },

        // More SubColumns...
    ];


    return (
        <ClientTable
            data={SubData}
            columns={SubColumns}
            skipStateReset={false}
            disableToolbar={true}
            disableHeaderRow
        />

    )
}
export default ExpandComponent