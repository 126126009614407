
import ActionColumn from "./ActionColumn/ActionColumn";

const columns: any[] = [

	{ Header: "Display", accessor: "display" },
	{ Header: "Ranking", accessor: "ranking", disableSortBy: false },
	{
		Header: "Action",
		accessor: "action",
		disableSortBy: true,
		Cell: ActionColumn,
	},
	{
		// Header: () => null,
		Header: "id",
		accessor: "id",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually

	},
	// { Header:  () => null, accessor: "measureValues",disableSortBy:true,width:null},
	// // More columns...
];

export default columns;
