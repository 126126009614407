import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import the validation module

const ActionCol: React.FC<{
    mode: 'new' | 'edit',
    ActionColInput: string,
    setActionColInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    ActionColInput,
    setActionColInput,
    initialize = false,
}) => {
        // Use the text validation hook
        const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setActionColInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handle focus and blur events for validation feedback
        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="Action-field" className="dds__label">
                    {mode === 'edit' ? 'Action' : 'Action'}
                    <span style={{ color: 'red' }}> *</span>
                </label>
                <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: "100%",
                            borderRadius: "1.5rem"
                        }}
                        name="Action-field"
                        id="Action-field"
                        required
                        aria-labelledby="Action-label"
                        onChange={(event) => validateInput(event.target.value)} // Validate input on change
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={ActionColInput}
                    />
                    {/* Show validation messages based on focus and validity */}
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && ActionColInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Valid input (up to 50 characters).
                        </span>
                    )}
                </div>
            </div>
        );
    };

export default ActionCol;
