const baseUrl = process.env.REACT_APP_BASE_URL;

export const createTelemetryMeasures = (): object => ({
	url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v2/utilisationRules",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateTelemetryMeasures = (): object => ({
	url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v2/utilisationRules",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getMeasuresConfig = () => {
	return {
		url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v2/utilisationRules",
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const getSearchMeasuresConfig = (q: string) => {
	return {
		url: `https://trackit-rest-api-automation-g1.anp2.pcf.dell.com/trackitservice/api/Fetch/v1/TelemertyMeasuresSearchValues?q=${q}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const measureValuesQueryConfig = () => ({
	url: "https://trackit-rest-api-automation-g1.anp2.pcf.dell.com/trackitservice/api/Fetch/v1/TelemertyMeasuresValues",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});


export const DeleteMeasuresQueryConfig = (id: number) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v2/utilisationRules?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}