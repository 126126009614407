import styled from "styled-components";

export const PageTitle = styled.div`
	color: white;
	padding-bottom: 15px;
	font-weight: bold;
	font-size: 1.5em;
`;

export const FilterCardWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	min-height: 120px;
    height: 100px;
	flex-wrap: wrap;
`;

export const columnMapping = {
	smartphones: {
		name: "Smart Phone",
		fontColor: '#1282D6',
		iconClass: 'dds__icon dds__icon--device-mobile'
	},
	featurephones: {
		name: "Feature Phone",
		fontColor: '#A64600',
		iconClass: 'dds__icon dds__icon--phone'
	},
	datacards: {
		name: "Data Card",
		fontColor: '#7D2E00',
		iconClass: 'dds__icon dds__icon--graphics-card'
	},
	mifi: {
		name: "MiFi",
		fontColor: '#0B7C84',
		iconClass: 'dds__icon dds__icon--removable-storage-usb'
	},
	laptop: {
		name: "Laptop",
		fontColor: '#002A58',
		iconClass: 'dds__icon dds__icon--device-laptop'
	},
	tablet: {
		name: "Tablet",
		fontColor: '#C96100',
		iconClass: 'dds__icon dds__icon--device-tablet-vert'
	},
	none: {
		name: "None",
		fontColor: '#044E52',
		iconClass: 'dds__icon dds__icon--objects'
	},
	unknown: {
		name: "Unknown",
		fontColor: '#CB548D',
		iconClass: 'dds__icon dds__icon--usb'
	}
};

export const CardContnet = styled.div<{ selected: boolean, disabled: boolean }>`
    height: 100px;
	min-width: 200px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	background-color: ${props => props.selected ? '#F5F6F7' : '#ffffff'};
	border-radius: 24px;
	border: ${props => props.selected ? '2px solid rgba(79, 116, 228, 1)' : '1px solid #D5DCE1'};
	cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
	margin-right: 16px;
	margin-bottom: 16px;
    margin-top: 12px;
	align-content: left;


	:hover{
		box-shadow: ${props => props.disabled ? 0 : '0px 3.6507456302642822px 2.2241404056549072px 0px rgba(36, 42, 48, 0.0253)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 8.744236946105957px 5.309579372406006px 0px rgba(36, 42, 48, 0.0364)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 16.188283920288086px 9.783900260925293px 0px rgba(36, 42, 48, 0.045)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 27.863855361938477px 16.727041244506836px 0px rgba(36, 42, 48, 0.0536)'};
		box-shadow: ${props => props.disabled ? 0 : '0px 48.94049072265625px 29.069475173950195px 0px rgba(36, 42, 48, 0.0647)'};
	}

	.card-title {
		margin-bottom: 16px;
		text-align: center;
		height: 24px;
		span {
			display: inline-block;
			img {
				height: 20px;
			}
			&.title {
				padding-left: 10px;
			}
		}
	}
	.card-count {
		text-align: center;
		.count {
			// font-weight: bold;
			font-size: 24px;
		}
	}
`;
