import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import the validation component

const SubMeasureBox: React.FC<{
    mode: 'new' | 'edit';
    SubMeasureInput: string;
    setSubMeasureInput: React.Dispatch<React.SetStateAction<string>>;
    initialize?: boolean;
}> = ({ mode, SubMeasureInput, setSubMeasureInput, initialize = false }) => {
    // Use the text validation hook
    const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setSubMeasureInput);
    const [isFocused, setIsFocused] = useState(false);

    // Handle focus to show validation state
    const handleFocus = () => {
        setIsFocused(true);
    };

    // Handle blur event
    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className="dds__input-text__container">
            <label htmlFor="SubMeasure-field" className="dds__label">
                {mode === 'edit' ? 'Sub Measure' : 'Sub Measure'}
                <span style={{ color: 'red' }}> *</span>
            </label>
            <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                <input
                    type="text"
                    className="dds__input-text"
                    style={{
                        width: '100%',
                        borderRadius: '1.5rem',
                    }}
                    placeholder=""
                    maxLength={100}
                    name="SubMeasure-field"
                    id="SubMeasure-field"
                    required
                    aria-labelledby="SubMeasure-label"
                    onChange={(event) => validateInput(event.target.value)}  // Use validateInput from UseTextValidation
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={SubMeasureInput}
                />
                {/* Show validation messages based on focus and validity */}
                {isFocused && !isValid && (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                        Accepts a valid string. {errorMessage}
                    </span>
                )}
                {isFocused && isValid && SubMeasureInput && (
                    <span style={{ color: 'green', fontSize: '12px' }}>
                        Accepts a valid string (upto 30 characters).
                    </span>
                )}
            </div>
        </div>
    );
};

export default SubMeasureBox;
