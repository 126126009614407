import { useEffect, useRef, useState } from "react";
import EditMeasuresModal from "../MeasuresModal/EditMeasuresModal";
import ToolbarButton from "./ToolbarButton"
const AddMeasuresButton = () => {
	let modalInstanceRef = useRef<any>();
	const [show, setShow] = useState(false);
	const openModal = () => {
		setShow(true);
	};

	useEffect(() => {
		console.log("trigger", show, modalInstanceRef)
		if (show) {
			if (modalInstanceRef.current) {
				modalInstanceRef.current.open();
			}
		}
	}, [show])


	const closeModal = () => {
		console.log("Close modal")
		setShow(false);
	}

	return <>
		<ToolbarButton onClick={openModal} iconClass="dds__icon--add-sqr">
			Add Measures
		</ToolbarButton>
		{show && <EditMeasuresModal handleClose={closeModal} mode='new' modalInstanceRef={modalInstanceRef} />}
	</>
}
export default AddMeasuresButton