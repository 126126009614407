import { objectToQuery } from "../../../../utils/querycreator";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const createFeaturesWithWeightage = (): object => ({
	url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/featuresWithWeightage",
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateFeaturesWithWeightage = (): object => ({
	url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/featuresWithWeightage",
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getFeaturesConfig = (dellID: String) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/featuresWithWeightage`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const getSearchMeasuresConfig = (q: string) => {
	return {
		url: baseUrl + `/api/Fetch/v1/TelemertyMeasuresSearchValues?q=${q}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const measureValuesQueryConfig = () => ({
	url: baseUrl + "/api/Fetch/v1/TelemertyMeasuresValues",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});


export const DeleteMeasuresQueryConfig = (id: number) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/featuresWithWeightage?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}