import React, { useState, useEffect } from 'react';
import UseYearValidation from '../../../../ValidationRules/UseYearValidation'; // Import the year validation component

const FinancialYearBox: React.FC<{
  mode: 'new' | 'edit';
  MeasureInput: string;
  setMeasureInput: React.Dispatch<React.SetStateAction<string>>;
  initialize?: boolean;
}> = ({ mode, MeasureInput, setMeasureInput, initialize = false }) => {
  // State to track focus
  const [isFocused, setIsFocused] = useState(false);

  // Use the year validation hook
  const { isValid, errorMessage, validateInput } = UseYearValidation(setMeasureInput);

  // Financial Year input change handler 
  const onMeasureInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    validateInput(input); // Validate the year input
  };

  // Handler for when the input loses focus
  const onInputBlur = () => {
    // If the input is not valid, reset it to an empty string
    if(MeasureInput.length<4){
      setMeasureInput('');
    }
    setIsFocused(false); // Mark the input as not focused
  };

  // Handler for when the input gains focus
  const onInputFocus = () => {
    setIsFocused(true); // Mark the input as focused
  };

  return (
    <div className="dds__input-text__container">
      <label htmlFor="Measure-field" className="dds__label">
        Financial Year
        <span style={{ color: 'red' }}> *</span>
      </label>
      <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
        <input
          type="text"
          className="dds__input-text"
          style={{
            width: '100%',
            borderRadius: '1.5rem',
          }}
          placeholder=""
          // maxLength={4} // Set maxLength to 4 for year input
          name="Measure-field"
          id="Measure-field"
          required
          aria-labelledby="Measure-label"
          onChange={onMeasureInputChange} // Use validation on change
          onBlur={onInputBlur} // Add onBlur handler
          onFocus={onInputFocus} // Add onFocus handler
          value={MeasureInput}
        />
        {/* Show validation message if the input is invalid and focused */}
        {isFocused && !isValid && (
          <span style={{ color: 'red', fontSize: '12px' }}>
            Accepts a valid year. {errorMessage}
          </span>
        )}
        {/* Show confirmation message if the input is valid and focused */}
        {isFocused && isValid && MeasureInput && (
          <span style={{ color: 'green', fontSize: '12px' }}>
            Accepts valid year, enter a 4 digit year (format: YYYY).
          </span>
        )}
      </div>
    </div>
  );
};

export default FinancialYearBox;
