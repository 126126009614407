import React, { useEffect } from 'react';
import { PageTitle } from '../../Admin/styles';
import { DisplayRankingTable } from "./DisplayRankingTable";
import columns from './DataModel/DataModel';
import AddMeasuresButton from './Toolbar/AddMeasuresButton';
import { queryKeys } from '../../../../react-query/constants';
import { TableContextProvider } from './TableContext';
import { getDisplayConfig } from './DisplayRankingAPI';
import BackButton from '../BackButton';
import NextButton from '../NextButton';
import ProgressBar from '../Pbar';
import { ContentBox, ProgressBox } from '../IpssPageStyles';

const DisplayRankingPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Asset Inventory | Display Ranking';
  }, []);

  return (
    <div className="dds__container-fluid dds__px-0">
      <PageTitle>PC Selector Criteria</PageTitle>
      <ContentBox>
        <ProgressBox>
          <ProgressBar />
        </ProgressBox>
        <TableContextProvider apiConfig={{ keyName: queryKeys.TelemetryMeasuresList, getDisplayConfig }}>
          <DisplayRankingTable AddMeasuresButton={AddMeasuresButton} columns={columns} />
        </TableContextProvider>
        <div style={{ float: 'right' }}>
          <BackButton url="/assetinventory/ipss/graphicsprocessorranking" />
          <NextButton url="/assetinventory/ipss/devicetyperanking" isLast={false} />
        </div>
      </ContentBox>
    </div>
  );
};

export default DisplayRankingPage;

