import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import the text validation hook

const Graphics: React.FC<{
    mode: 'new' | 'edit',
    GraphicsInput: string,
    setGraphicsInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    GraphicsInput,
    setGraphicsInput,
    initialize = false,
}) => {
        // Use the text validation hook
        const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setGraphicsInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handle focus to show validation state
        const handleFocus = () => {
            setIsFocused(true);
        };

        // Handle blur event
        const handleBlur = () => {
            setIsFocused(false);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="Graphics-field" className="dds__label">
                    {mode === 'edit' ? 'Graphics' : 'Graphics'}<span style={{ color: 'red' }}> *</span>
                </label>
                <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: "100%",
                            borderRadius: "1.5rem"
                        }}
                        placeholder=""
                        maxLength={100}
                        name="Graphics-field"
                        id="Graphics-field"
                        required
                        aria-labelledby="Graphics-label"
                        onChange={(event) => validateInput(event.target.value)} // Use validation
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={GraphicsInput}
                    />
                    {/* Show validation messages based on focus and validity */}
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && GraphicsInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Input is valid.
                        </span>
                    )}
                </div>
            </div>
        );
    }

export default Graphics;
