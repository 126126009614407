import { useEffect, useState } from "react";
import EditAdminModal from "../../AdminModal/EditAdminModal";
import ToolbarButton from "./ToolbarButton";
import { useUserInfo } from "../../../../../store/auth/UserInfoContext";
import { useErrorHandler } from "react-error-boundary";
import { inventory } from "../../../../Dashboardv3/constants";
import { APIRequestFailedError, AuthError } from "../../../../../ErrorStates";
import { useCustomQuery } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { assetInventoryPermissionList } from "../../../API";
import { getSpecificValue } from "../../../../Dashboardv3/Drawer/PermissionStorageUtils";

const CreateAdminButton = () => {
	const [show, setShow] = useState(false);

	const user = useUserInfo();
	const handleError = useErrorHandler();
	/* const [moduleId, setModuleId] = useState<number>();
	useEffect(() => {
		if (user) {
			const res = user?.getUserInfo();
			const role = user?.getUserRole(inventory);
			setModuleId(role?.moduleID);

		}
	}, [user, handleError]); */

	const savedData = localStorage.getItem("PermissionCodes") || "{}";
	const parsedData = JSON.parse(savedData);
	const [assetTrackerValue, setAssetTrackerValue] = useState(parsedData["Asset Tracker"] || 0);
	const [error, setError] = useState<string>()


	const { isLoading, error: fetchError, data: permissionData } = useCustomQuery([queryKeys.assetIventoryPermission],
		assetInventoryPermissionList(assetTrackerValue),
		{
			select: (data) => {
				return data.data;
			}
		},
	);
	useEffect(() => {
		if (fetchError) {
			console.log("error on component", fetchError)
			if (fetchError instanceof APIRequestFailedError) {
				if (fetchError.statusCode === "201")
					setError("No Record Found")
				else if (fetchError.statusCode === "204")
					setError(fetchError.message)
				else
					handleError(fetchError)
			} else if (fetchError instanceof AuthError) {
				handleError(fetchError)
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [fetchError, handleError])

	console.log("============>permission", permissionData)

	return <>
		<ToolbarButton onClick={() => {
			setShow(true);
		}} iconClass="dds__icon--user-add"
		>Create Admin</ToolbarButton>
		{show && (
			<EditAdminModal
				handleClose={() => {
					setShow(false);
				}}
				mode="new"
				permissionData={permissionData}
				moduleId={assetTrackerValue}
			></EditAdminModal>
		)}
	</>
}
export default CreateAdminButton