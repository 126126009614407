import React, { useEffect, useState, useContext } from "react";
import DDSModal from "../../../../Common/Modalv2/DDSModal";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { useAlert } from "react-alert";
import FinancialYearBox from "./ModalForm/InputFields/FinancialYearBox";
import Processor from "./ModalForm/InputFields/Processor";
import Persona from "./ModalForm/InputFields/Persona";
import DeviceName from "./ModalForm/InputFields/DeviceName";
import DeviceType from "./ModalForm/InputFields/DeviceType";
import Memory from "./ModalForm/InputFields/Memory";
import Storage from "./ModalForm/InputFields/Storage";
import Graphics from "./ModalForm/InputFields/Graphics";
import Display from "./ModalForm/InputFields/Display";
import Thunderbolt from "./ModalForm/InputFields/Thunderbolt";
import Battery from "./ModalForm/InputFields/Battery";
import Connectivity from "./ModalForm/InputFields/Connectivity";
import IsExclusive from "./ModalForm/InputFields/IsExclusive";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import "./FormStyles.css";
import { createCatalog, updateCatalog } from '../CatalogAPI';
import { useQueryClient } from "@tanstack/react-query";
import { TableContext } from "../TableContext";

const EditMeasuresModal: React.FC<{
	mode: "new" | "edit";
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	MeasuresDetails?: {
		financialYear: string;
		processor: string;
		persona: string;
		deviceName: string;
		deviceType: string;
		memoryInGb: string;
		storage: string;
		graphics: string;
		display: string;
		thunderbolt: string;
		battery: string;
		connectivity: string;
		is_exclusive: string;
		id: number;
	};
}> = ({ mode, modalInstanceRef, MeasuresDetails, handleClose }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData, setMappedData } = useContext(TableContext);

	const [MeasureInput, setMeasureInput] = useState(
		MeasuresDetails?.financialYear || ""
	);
	const [ProcessorInput, setProcessorInput] = useState(
		MeasuresDetails?.processor || ""
	);
	const [PersonaInput, setPersonaInput] = useState(
		MeasuresDetails?.persona || ""
	);
	const [DeviceNameInput, setDeviceNameInput] = useState(
		MeasuresDetails?.deviceName || ""
	);
	const [DeviceTypeInput, setDeviceTypeInput] = useState(
		MeasuresDetails?.deviceType || ""
	);
	const [MemoryInput, setMemoryInput] = useState(
		MeasuresDetails?.memoryInGb || ""
	);
	const [StorageInput, setStorageInput] = useState(
		MeasuresDetails?.storage || ""
	);
	const [GraphicsInput, setGraphicsInput] = useState(
		MeasuresDetails?.graphics || ""
	);
	const [DisplayInput, setDisplayInput] = useState(
		MeasuresDetails?.display || ""
	);
	const [ThunderboltInput, setThunderboltInput] = useState(
		MeasuresDetails?.thunderbolt || ""
	);
	const [BatteryInput, setBatteryInput] = useState(
		MeasuresDetails?.battery || ""
	);
	const [ConnectivityInput, setConnectivityInput] = useState(
		MeasuresDetails?.connectivity || ""
	);
	const [IsExclusiveInput, setIsExclusiveInput] = useState(
		MeasuresDetails?.is_exclusive || ""
	);

	const saveSuccessHandler = (data: any) => {

		modalInstanceRef.current.close();

		refetchData();
		alert.show("Saving...", {
			type: "success",
			timeout: 1000,
		});
		// Wait for 1 seconds before showing the alert
		setTimeout(() => {
			alert.show("Saved", {
				type: "success",
				timeout: 5000,
			});
		}, 1000);
	};

	const { mutate: saveAPI, isLoading: isSaving } = useCustomMutation(
		createCatalog(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.log("Create error")
				console.error(error);
				alert.show("Error Occurred while saving, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const { mutate: updateAPI, isLoading: isUpdating } = useCustomMutation(
		updateCatalog(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.log("Update error")
				console.error(error);
				alert.show("Error Occurred while updating, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const updateDetails = () => {

		const requestBody = {
			financialYear: MeasureInput,
			processor: ProcessorInput,
			persona: PersonaInput,
			deviceName: DeviceNameInput,
			deviceType: DeviceTypeInput,
			memoryInGb: MemoryInput,
			storage: StorageInput,
			graphics: GraphicsInput,
			display: DisplayInput,
			thunderbolt: ThunderboltInput,
			battery: BatteryInput,
			connectivity: ConnectivityInput,
			is_exclusive: IsExclusiveInput,
			id: MeasuresDetails?.id,
		};

		const saveObject = {
			...requestBody,
		};

		if (mode === "new") {
			saveAPI(saveObject);
		} else {
			updateAPI(saveObject);
		}
	};



	const CancelDetails = () => {
		modalInstanceRef.current.close();
	};

	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isSaving || isUpdating) {
			setShowError(false);
		} else {
			setShowError(!(MeasureInput && PersonaInput && DeviceTypeInput && DeviceNameInput && ProcessorInput && MemoryInput && StorageInput && GraphicsInput));
		}
	}, [isSaving, isUpdating, MeasureInput, PersonaInput, DeviceTypeInput, DeviceNameInput, ProcessorInput, MemoryInput, StorageInput, GraphicsInput]);

	return (
		<DDSModal
			themeColor={"white"}
			iconClass={"dds__icon--diagnostic"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__modal--lg"}
			id={
				mode === "new"
					? "AddMeasures"
					: MeasuresDetails?.financialYear || ""
			}
			title={mode === "new" ? "Add Measures - Catalog" : "Edit Measures"}
			disposeOnClose={true}
			footerButtons={[
				{
					class: "dds__button--secondary",
					text: "Cancel",
					handleFooterButtonClick: CancelDetails,
					disabled: (isSaving || isUpdating),
				},
				{
					class: "dds__button--primary",
					text: (mode === "edit") ? "Edit measures" : "Add measures",
					handleFooterButtonClick: updateDetails,
					disabled: (showError || (isSaving || isUpdating)),
				},
			]}
			onClose={handleClose}
		>
			<>
				{!(isSaving || isUpdating) && (
					<>
						<div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "1rem", transform: "scale(0.9)" }}>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<FinancialYearBox
										mode={mode}
										MeasureInput={MeasureInput}
										setMeasureInput={setMeasureInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Persona
										mode={mode}
										PersonaInput={PersonaInput}
										setPersonaInput={setPersonaInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<DeviceType
										mode={mode}
										DeviceTypeInput={DeviceTypeInput}
										setDeviceTypeInput={setDeviceTypeInput}
										initialize={mode === "new"}
									/>
								</div>
							</div>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<DeviceName
										mode={mode}
										DeviceNameInput={DeviceNameInput}
										setDeviceNameInput={setDeviceNameInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Processor
										mode={mode}
										ProcessorInput={ProcessorInput}
										setProcessorInput={setProcessorInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Memory
										mode={mode}
										MemoryInput={MemoryInput}
										setMemoryInput={setMemoryInput}
										initialize={mode === "new"}
									/>
								</div>
							</div>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<Storage
										mode={mode}
										StorageInput={StorageInput}
										setStorageInput={setStorageInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Graphics
										mode={mode}
										GraphicsInput={GraphicsInput}
										setGraphicsInput={setGraphicsInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Display
										mode={mode}
										DisplayInput={DisplayInput}
										setDisplayInput={setDisplayInput}
										initialize={mode === "new"}
									/>
								</div>

							</div>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<Thunderbolt
										mode={mode}
										ThunderboltInput={ThunderboltInput}
										setThunderboltInput={setThunderboltInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Battery
										mode={mode}
										BatteryInput={BatteryInput}
										setBatteryInput={setBatteryInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<Connectivity
										mode={mode}
										ConnectivityInput={ConnectivityInput}
										setConnectivityInput={setConnectivityInput}
										initialize={mode === "new"}
									/>
								</div>
							</div>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "0.32" }}>
									<IsExclusive
										mode={mode}
										IsExclusiveInput={IsExclusiveInput}
										setIsExclusiveInput={setIsExclusiveInput}
										initialize={mode === "new"}
									/>
								</div>
							</div>
						</div>
					</>
				)}
				<br />
				{(isSaving || isUpdating) && (
					<LoadingSpinner size="sm" label="Saving" />
				)}

			</>
		</DDSModal>
	);
};

export default EditMeasuresModal;
