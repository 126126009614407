import { PageTitle } from "../../Admin/styles";
import { RegionContextProvider } from "./RegionContext";
import { ManageUsersContextProvider } from "./ManageUsersContext";
import { ManageUsersTable } from "./ManageUsersTable";
import { assetInventoryPermissionList, getAdminsConfig } from "../../API";
import { queryKeys } from "../../../../react-query/constants";
import columns from '../DataModel/DataModel'
import CreateAdminButton from "./Toolbar/CreateAdminButton";
import { useEffect, useState } from "react";
import { useUserInfo } from "../../../../store/auth/UserInfoContext";
import { inventory } from "../../../Dashboardv3/constants";
import { useErrorHandler } from "react-error-boundary";

const ManageUsersPage: React.FC = () => {

	useEffect(() => {
		document.title = "Asset Inventory | Manage Users"
	}, [])

	const savedData = localStorage.getItem("PermissionCodes") || "{}";
	const parsedData = JSON.parse(savedData);
	const [assetTrackerValue, setAssetTrackerValue] = useState(parsedData["Asset Tracker"] || 0);


	return (
		<div className="dds__container-fluid dds__px-0">
			<PageTitle>Manage Administrator</PageTitle>
			<RegionContextProvider>
				<ManageUsersContextProvider apiConfig={{ keyName: queryKeys.inventoryadminlist, getAdminsConfig: getAdminsConfig(assetTrackerValue) }}>
					<ManageUsersTable CreateAdminButton={CreateAdminButton} columns={columns} />
				</ManageUsersContextProvider>
			</RegionContextProvider>
		</div>
	);
};

export default ManageUsersPage;