import axios, { AxiosResponse } from "axios";
import { useAlert } from "react-alert";
import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import {
	APIRequestFailedError,
	AuthError,
	UnauthorizedError,
} from "../ErrorStates";
import { queryClient } from "../react-query/queryClient";
import { useErrorHandler } from "react-error-boundary";
import { queryKeys as queryKeysConstants } from "../react-query/constants";
import { useAnalytics } from "../store/analytics/AnalyticsContext";
import { eventsInfo } from "../store/analytics/eventsInfo";
import { useIsAuthenticated } from "@azure/msal-react";
import { useAuth } from "./useAuth";
let relogin_state = false;
// const apikey = process.env.REACT_APP_L7_APIKEY;
class CustomAxiosError extends Error {
    config: any;
    request: any;
    response: any;

    constructor(message: string, config: any, request: any, response: any) {
        super(message);
        this.config = config;
        this.request = request;
        this.response = response;
    }
}


const apikey = process.env.REACT_APP_L7_APIKEY;

export const fetchFunction = async (requestConfig, headers, auth) => {
	const token = await auth.getAccessToken();
	console.log("token", token);
	if(requestConfig.url.toLowerCase().includes(".anp2.pcf.dell.com")){
		headers = {
			...headers,
			Authorization: `Bearer ${token!.replace(/\n|\r/g, "")}`, // added for PCF URLs
		};
	}else
		headers = {
			...headers,
			jwt_token: `${token!.replace(/\n|\r/g, "")}`, // For L7 URLs
			apikey: apikey, // For L7 URLs
		};
	const response: AxiosResponse<any> =
		requestConfig.method.toLowerCase() === "get" ||
		(requestConfig.method.toLowerCase() === "delete" &&
			requestConfig.body === null)
			? await axios[requestConfig.method.toLowerCase()](requestConfig.url, {
					headers,
					responseType: requestConfig.responseType,
			  })
			: requestConfig.method.toLowerCase() === "delete"
			? await axios[requestConfig.method.toLowerCase()](requestConfig.url, {
					data: requestConfig.body,
					headers,
			  })
			: await axios[requestConfig.method.toLowerCase()](
					requestConfig.url,
					requestConfig.body
						? headers["Content-Type"] === "application/x-www-form-urlencoded" || headers["Content-Type"] === "multipart/form-data" // added exception for /token API with different content type
							? requestConfig.body
							: JSON.stringify(requestConfig.body)
						: null,
					{
						headers,
					}
			  );
	// console.log("data sent", requestConfig.url, requestConfig.body);
	// if (~requestConfig.url.indexOf("/api/Fetch/v1/UserInfo")) {
	// 	console.log("HERE");
	// 	response.status = 400;
	// 	response.statusText = "Unauthorized";
	// 	response.data = {
	// 		error: "error fake",
	// 		code: 401,
	// 	};
	// }
	console.log("Response", response);
	if (response.status !== 200) {
		if (response.status === 401 && !requestConfig.url.includes(process.env.REACT_APP_PROFILE_IMAGE_URL)) {
			let e = new CustomAxiosError("Authentication Error", requestConfig, {}, response);
			console.log("e", e);
			throw e;
			// Promise.reject(e); // doesn't return response
		} else
			throw new APIRequestFailedError(response.status, response.statusText);
	}

	const { data } = response;
	if (data.code)
		if (
			data.code === 200 ||
			(data.code === 201)
			// 201 is not a error for GET response as it means no record found but request is valid
			// 201 is an error(4XX type of) for POST response
		) {
			return data;
		} else if (data.code === 401 || data.code === 202) {
			// If API status is 200 but data has code 401 then user is unauthorized(status should be 401)
			// If API status is 200 but data has code 202 then user is forbidden to access(status should be 403)
			response.status = data.code === 202 ? 403 : 401;
			response.statusText = data.code === 202 ? "Forbidden" : "Unauthorized";
			let e = new CustomAxiosError("Authentication Error", requestConfig, {}, response);
			console.log("e", e);
			throw e;
		} else {
			throw new APIRequestFailedError(data.code, data.message);
		}
	else return data;
};

export const useCustomMutation =  (
	requestConfig: any,
	options: any,
	unsecure?: boolean // unused - implementation required if we want to use unsecure APIs
) => {
	const alert = useAlert();
	const auth = useAuth();

	/* For analytics */
	const analytics = useAnalytics();
	let startTime = 0;
	const customOnMutate = (data: any) => {
		startTime = Date.now();
		if (options && options.onMutate) options.onMutate(data);
	};
	const customOnSettled = (data: any, error: any) => {
		// Analytics
		console.log("mutation settled " + requestConfig.url + " " + analytics);
		// if analytics is defined, url doesn't contain "Delllytics"
		if (analytics && !~requestConfig.url.indexOf("DellLytics")) {
			console.log("Adding mutation");
			analytics.addEventToQueue({
				...eventsInfo["API Call"](),
				status_code: error ? error.response.status : 200,
				status_message: error
					? "error: " + error.response.statusText
					: "success",
				additional_payload: {
					pathname: new URL(requestConfig.url).pathname,
					baseurl: new URL(requestConfig.url).origin,
					method: requestConfig.method,
				},
				response_time: startTime !== 0 ? Date.now() - startTime : 0,
			});
		}
		if (options && options.onSettled) options.onSettled(data, error);
	};
	/* For analytics */

	let headers = requestConfig.headers ? requestConfig.headers : {};

	const mutationFn = (body: any) =>
		fetchFunction({ ...requestConfig, body: body }, headers, auth);
	// if options have atleast one key value pair
	if (Object.keys(options).length > 0) {
		return useMutation(mutationFn, {
			...options,
			onSettled: customOnSettled,
			onMutate: customOnMutate,
			// useErrorBoundary: true,
		});
	} else
		return useMutation(mutationFn, {
			onSettled: customOnSettled,
			onMutate: customOnMutate,
		});
};
// TODO: Add logging for prefetch APIs
export const prefetchQuery = async (
	queryKeys: any[],
	requestConfig: any,
	queryOptions?: Omit<
		UseQueryOptions<any, unknown, any, any[]>,
		"queryFn" | "queryKey"
	>,
	unsecure?: boolean, // implementation required if we want to use unsecure APIs
	// auth?: any
) => {
	let headers = requestConfig.headers ? requestConfig.headers : {};
	const auth = useAuth();
	// if (unsecure || auth.isAuthenticated()) {
	// 	if (!unsecure) {
	// 		const token = auth.getAccessToken();
	// 		headers = {
	// 			...headers,
	// 			Authorization: `${token!.replace(/\n|\r/g, "")}`,
	// 			// apikey: apikey,
	// 		};
	// 	}
	// } else return;

	return await queryClient.prefetchQuery(
		queryKeys,
		() => fetchFunction(requestConfig, headers, auth),
		queryOptions
	);
};

export const useCustomQuery =  (
	queryKeys: any[],
	requestConfig: any,
	queryOptions?: Omit<
		UseQueryOptions<any, unknown, any, any[]>,
		"queryFn" | "queryKey"
	>,
	unsecure?: boolean // unused - implementation required if we want to use unsecure APIs
) => {
	const alert = useAlert();
	const auth = useAuth();
	const isAuthenticated = useIsAuthenticated();
	const handleError = useErrorHandler();
	let headers = requestConfig.headers ? requestConfig.headers : {};
	// if (unsecure || isAuthenticated) {
	// 	if (!unsecure) {
			
	// 	}
	// } else {
	// 	// if (relogin_state === false) {
	// 	// 	alert.show("Session expired, redirecting to login page", {
	// 	// 		timeout: 1000,
	// 	// 		type: "info",
	// 	// 		onClose: () => {
	// 	// 			auth.login();
	// 	// 		},
	// 	// 	});
	// 	// }
	// 	// relogin_state = true;
	// 	// return;
	// }

	// TODO: Queries called twice together will be logged twice like Cards API, Picture API, Individual Cards APIs
	/* For analytics */
	const analytics = useAnalytics();
	let startTime = 0;

	// set start time if there is no enabled parameter or if it is there, it is true
	if (!queryOptions || (queryOptions && queryOptions.enabled !== false))
		startTime = Date.now();
	const customOnSettled = (data: any, error: any) => {
		// Analytics
		console.log("Query settled " + requestConfig.url + " " + startTime);
		// if analytics is defined, url doesn't contain "Delllytics" and query is enabled
		if (
			analytics &&
			!~requestConfig.url.indexOf("DellLytics") &&
			startTime !== 0
		) {
			analytics.addEventToQueue({
				...eventsInfo["API Call"](),
				status_code: error ? error.response.status : 200,
				status_message: error
					? "error: " + error.response.statusText
					: "success",
				additional_payload: {
					pathname: new URL(requestConfig.url).pathname,
					baseurl: new URL(requestConfig.url).origin,
					method: requestConfig.method,
				},
				response_time: startTime !== 0 ? Date.now() - startTime : 0,
			});
		}
		if (queryOptions && queryOptions.onSettled)
			queryOptions.onSettled(data, error);
	};
	/* For analytics */

	const customOnError = (error: any) => {
		console.log("Query caught error: ", error, requestConfig);
		let flag = 0;
		if (error && !requestConfig.url.includes(process.env.REACT_APP_PROFILE_IMAGE_URL)) {
			console.log("Repsp", error.response);
			if (error.response) {
				if (error.response.status === 401) {
					flag = 1;
					if (queryKeys[0] === queryKeysConstants.userinfo)
						handleError(
							new AuthError(
								"We are unable to fetch your information. Please try again later"
							)
						);
					else handleError(new AuthError());
				} else if (error.response.status === 403) {
					flag = 1;
					console.log("error response", error.response.data.message);
					handleError(new UnauthorizedError());
				}
				// else
				// 	throw new APIRequestFailedError(error.response.status, error.response.status);
			}
		}
		if (flag == 0 && queryOptions && queryOptions.onError)
			queryOptions.onError(error);
	};
	return useQuery(queryKeys, () => fetchFunction(requestConfig, headers, auth), {
		...queryOptions,
		onError: customOnError,
		onSettled: customOnSettled,
		// useErrorBoundary: true,
	});
};
