import { useState } from 'react';

const UseLongTextValidation = (setInput: React.Dispatch<React.SetStateAction<string>>) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const MAX_LENGTH = 300;

    // Validate input based on the provided conditions
    const validateInput = (value: string) => {
        // Allow empty input
        if (value === '') {
            setInput(value);
            setIsValid(true);
            setErrorMessage('');
            return;
        }

        // Check if the input is only a single space
        if (value.trim() === '' && value.length === 1) {
            setIsValid(false);
            setErrorMessage('Please input a character. Input cannot be a single space.');
            return;
        }

        // Check if the input exceeds the maximum length (excluding spaces)
        if (value.replace(/\s/g, '').length > MAX_LENGTH) {
            setIsValid(false);
            setErrorMessage(`Input cannot exceed ${MAX_LENGTH} characters, excluding spaces.`);
            return;
        }

        // Check for multiple consecutive spaces
        const consecutiveSpacesRegex = /\s{2,}/;
        if (consecutiveSpacesRegex.test(value)) {
            setIsValid(false);
            setErrorMessage('Only one space allowed between words or letters.');
            return;
        }

        // Check for alphanumeric characters and special characters including _ and -
        const alphanumericAndSpecialCharRegex = /^[a-zA-Z0-9\s_\-?%,/().]+$/;

        if (!alphanumericAndSpecialCharRegex.test(value)) {
            setIsValid(false);
            setErrorMessage('Character inputted not allowed.');
            return;
        }

        // If all validations pass, set the input and mark as valid
        setInput(value);
        setIsValid(true);
        setErrorMessage('');
    };

    return { isValid, errorMessage, validateInput, setIsValid };
};

export default UseLongTextValidation;
