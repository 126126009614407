import ReactTooltip from "react-tooltip";
import DefaultCell from "../../../../Table/ReusableTableCells/DefaultCell";
import ActionColumn from "./ActionColumn/ActionColumn";
import { IconButtonWrapper } from "../../../../Dashboardv3/Card/Styles";
import { UseTableCellProps } from "react-table";
const columns: any[] = [

	{ Header: "Field Name", accessor: "fieldName" },
	{ Header: "Feature Name", accessor: "featureName", disableSortBy: true },
	{ Header: "Feature Weight", accessor: "featureWeightage", disableSortBy: false, width: 20 },
	{ Header: "Is Used", accessor: "isUsed", disableSortBy: false, width: 20, },
	{ Header: "Feature Description", accessor: "featureDescription", disableSortBy: true, width: 20 },
	{
		Header: "Action",
		accessor: "action",
		disableSortBy: true,
		Cell: ActionColumn,
	},
	{
		// Header: () => null,
		Header: "measureValues",
		accessor: "measure_Values",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually

	},
	{
		// Header: () => null,
		Header: "id",
		accessor: "id",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually

	},
	// { Header:  () => null, accessor: "measureValues",disableSortBy:true,width:null},
	// // More columns...
];

export default columns;
