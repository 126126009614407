import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = ({ url }) => {
  const history = useHistory();

  const handleBack = () => {
    history.push(url);
  };

  return (
    <button
      aria-label="back"
      role="button"
      onClick={handleBack}
      className="dds__button dds__button--sm dds__button--secondary dds__mx-2 dds__mb-2"
    >
      <i aria-hidden="true" className="dds__icon dds__icon--arrow-left dds__button__icon--start" />
      Back
    </button>
  );
};

export default BackButton;
