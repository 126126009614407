import { useState } from 'react';

const UseYearValidation = (setYearInput: React.Dispatch<React.SetStateAction<string>>) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    // Validate input based on the provided conditions
    const validateInput = (value: string) => {
        // Allow empty input
        if (value === '') {
            setYearInput(value);
            setIsValid(true);
            setErrorMessage('');
            return;
        }

        // Check for spaces
        if (/\s/.test(value)) {
            setIsValid(false);
            setErrorMessage('Only numbers are allowed, spaces are not allowed.');
            return;
        }

        // Check for alphabets
        if (/[a-zA-Z]/.test(value)) {
            setIsValid(false);
            setErrorMessage('Only numbers are allowed, no letters.');
            return;
        }

        // Check for special characters
        if (/[^0-9]/.test(value)) {
            setIsValid(false);
            setErrorMessage('Only numbers are allowed, no special characters.');
            return;
        }

        // Check if the input exceeds 4 digits
        if (value.length > 4) {
            setIsValid(false);
            setErrorMessage('Input cannot exceed 4 digits.');
            return;
        }

        // If all validations pass, set the input and mark as valid
        setYearInput(value);
        setIsValid(true);
        setErrorMessage('');
    };

    return { isValid, errorMessage, validateInput, setIsValid };
};

export default UseYearValidation;
