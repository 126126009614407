import React, { useState } from 'react';
import UseNumberValidation from '../../../../ValidationRules/UseNumbervalidation';

const HighThreshold: React.FC<{
    mode: 'new' | 'edit',
    HighThresholdInput: string,
    setHighThresholdInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    HighThresholdInput,
    setHighThresholdInput,
    initialize = false,
}) => {
        const { isValid, errorMessage, validateInput, setIsValid } = UseNumberValidation(setHighThresholdInput);
        const [isFocused, setIsFocused] = useState(false);

        const handleBlur = () => {
            setIsFocused(false);
            if (HighThresholdInput) {
                const roundedValue = HighThresholdInput.includes('.') ? parseFloat(HighThresholdInput).toFixed(2) : HighThresholdInput;;
                setHighThresholdInput(roundedValue);
            }
        };

        const handleFocus = () => {
            setIsFocused(true);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="HighThreshold-field" className="dds__label">
                    {mode === 'edit' ? 'HighThreshold' : 'High Threshold'}
                    <span style={{ color: 'red' }}> *</span>
                </label>
                <div className="dds__input-text__wrapper">
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: "100%",
                            borderRadius: "1.5rem",
                            outline: 'none'
                        }}
                        // maxLength={100}
                        name="HighThreshold-field"
                        id="HighThreshold-field"
                        required
                        aria-labelledby="HighThreshold-label"
                        onChange={(event) => validateInput(event.target.value)}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={HighThresholdInput}
                    />
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            Accepts a valid number. {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && HighThresholdInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Accepts a valid number (up to 2 decimal places).
                        </span>
                    )}
                </div>
            </div>
        );
    }

export default HighThreshold;
