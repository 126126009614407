// import React, { useState, useRef, useEffect } from 'react';
// import BackButton from '../IPSSMeasures/BackButton';
// import NextButton from '../IPSSMeasures/NextButton';
// import ProgressBar from './Pbar';
// import { PageTitle } from '../Admin/styles';
// import { ContentBox, ProgressBox } from './IpssPageStyles';

// const RunModelPage: React.FC = () => {
//     const [response, setResponse] = useState('');
//     const [error, setError] = useState('');
//     const [showMessage, setShowMessage] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const nextButtonRef = useRef<HTMLDivElement | null>(null);

//     useEffect(() => {
//         const handleNextClick = () => {
//             setShowMessage(true);
//         };

//         const nextButton = nextButtonRef.current;
//         if (nextButton) {
//             nextButton.addEventListener('click', handleNextClick);
//         }

//         return () => {
//             if (nextButton) {
//                 nextButton.removeEventListener('click', handleNextClick);
//             }
//         };
//     }, []);

//     useEffect(() => {
//         fetchLastRetrainTime();
//     }, []);

//     const fetchLastRetrainTime = async () => {
//         setLoading(true);
//         try {
//             // This was the bypoc url, https://ipss-ml.gpu.aiaccel.dell.com/getLastReTrainTime
//             const response = await fetch("https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/lastReTrainTime", {
//                 method: "GET",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//             });
//             const data = await response.json();
//             if (response.ok) {
//                 const UTCdate = new Date(data.data.data);
//                 // Convert UTC date to local date
//                 const localOffset = UTCdate.getTimezoneOffset() * 60000;
//                 const LocalDate = new Date(UTCdate.getTime() - localOffset);  // Do not call toLocaleString here

//                 // Format the local date in the desired format
//                 const options: Intl.DateTimeFormatOptions = {
//                     weekday: 'long', // 'long', 'short', or 'narrow'
//                     year: 'numeric', // 'numeric' or '2-digit'
//                     month: 'long', // 'numeric', '2-digit', 'long', 'short', or 'narrow'
//                     day: 'numeric', // 'numeric' or '2-digit'
//                     hour: 'numeric', // 'numeric' or '2-digit'
//                     minute: 'numeric', // 'numeric' or '2-digit'
//                     hour12: true // boolean
//                 };

//                 // Now call toLocaleString with options on the LocalDate object
//                 const formattedDate = LocalDate.toLocaleString(undefined, options);

//                 // Set the formatted date to the response
//                 setResponse(formattedDate);
//             } else {
//                 setError(data.status_message || "Error fetching data");
//             }
//         } catch (error) {
//             setError("Network error");
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="dds__container-fluid dds__px-0">
//             <PageTitle>PC Selector Criteria</PageTitle>
//             <ContentBox>
//                 <ProgressBox>
//                     <ProgressBar />
//                 </ProgressBox>
//                 <div className="dds__d-flex dds__justify-content-center dds__text-center">
//                     <div className="dds__text-center">
//                         <div className='dds__px-5' style={{ marginTop: "2rem" }}>
//                             {loading && <p>Loading...</p>}
//                             <h4 style={{ marginBottom: "1rem" }}>
//                                 {response && <p>Last Updated: {response}</p>}
//                             </h4>
//                             {error && <p>Error: {error}</p>}
//                             {showMessage && (
//                                 <p className="dds__body-2">Retraining of the model is in progress...
//                                     An email will be sent once the retraining is completed. Please use the recommendation model post retraining.
//                                 </p>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 <div style={{ float: 'right', display: "flex", flexDirection: "row" }}>
//                     <BackButton url="/assetinventory/ipss/devicetyperanking" />
//                     <div ref={nextButtonRef}>
//                         <NextButton url={""} isLast={true} />
//                     </div>
//                 </div>
//             </ContentBox>
//         </div>
//     );
// };

// export default RunModelPage;

import React, { useState, useRef, useEffect } from 'react';
import BackButton from '../IPSSMeasures/BackButton';
import NextButton from '../IPSSMeasures/NextButton';
import ProgressBar from './Pbar';
import { PageTitle } from '../Admin/styles';
import { ContentBox, ProgressBox } from './IpssPageStyles';
import { useAuth } from '../../../hooks/useAuth';

const RunModelPage: React.FC = () => {
    const [response, setResponse] = useState('');
    const [error, setError] = useState('');
    const [showMessage, setShowMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const nextButtonRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleNextClick = () => {
            setShowMessage(true);
        };

        const nextButton = nextButtonRef.current;
        if (nextButton) {
            nextButton.addEventListener('click', handleNextClick);
        }

        return () => {
            if (nextButton) {
                nextButton.removeEventListener('click', handleNextClick);
            }
        };
    }, []);

    useEffect(() => {
        fetchLastRetrainTime();
    }, []);
    const auth = useAuth();


    const fetchLastRetrainTime = async () => {
        setLoading(true);
        const apikey = process.env.REACT_APP_L7_APIKEY;
        const token = await auth.getAccessToken();
        const URL = `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/lastReTrainTime`;
        try {        
            // This was the bypoc url, https://ipss-ml.gpu.aiaccel.dell.com/getLastReTrainTime
            const response = await fetch(URL, {
                method: "GET",
                headers: URL.toLowerCase().includes(".anp2.pcf.dell.com") ? {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token!.replace(/\n|\r/g, "")}`, // added for PCF URLs

                } as HeadersInit : {
                    'Content-Type': 'application/json',
                    jwt_token: `${token!.replace(/\n|\r/g, "")}`, // For L7 URLs
                    apikey: apikey, // For L7 URLs
                } as HeadersInit,
            });
            const data = await response.json();
            if (response.ok) {
                const UTCdate = new Date(data.data.data);
                // Convert UTC date to local date
                const localOffset = UTCdate.getTimezoneOffset() * 60000;
                const LocalDate = new Date(UTCdate.getTime() - localOffset);  // Do not call toLocaleString here

                // Format the local date in the desired format
                const options: Intl.DateTimeFormatOptions = {
                    weekday: 'long', // 'long', 'short', or 'narrow'
                    year: 'numeric', // 'numeric' or '2-digit'
                    month: 'long', // 'numeric', '2-digit', 'long', 'short', or 'narrow'
                    day: 'numeric', // 'numeric' or '2-digit'
                    hour: 'numeric', // 'numeric' or '2-digit'
                    minute: 'numeric', // 'numeric' or '2-digit'
                    hour12: true // boolean
                };

                // Now call toLocaleString with options on the LocalDate object
                const formattedDate = LocalDate.toLocaleString(undefined, options);

                // Set the formatted date to the response
                setResponse(formattedDate);
            } else {
                setError(data.status_message || "Error fetching data");
            }
        } catch (error) {
            setError("Network error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="dds__container-fluid dds__px-0">
            <PageTitle>PC Selector Criteria</PageTitle>
            <ContentBox>
                <ProgressBox>
                    <ProgressBar />
                </ProgressBox>
                <div className="dds__d-flex dds__justify-content-center dds__text-center">
                    <div className="dds__text-center">
                        <div className='dds__px-5' style={{ marginTop: "2rem" }}>
                            {loading && <p>Fetching Last Retrain Time...</p>}
                            <h4 style={{ marginBottom: "1rem" }}>
                                {response && <p>Last Updated: {response}</p>}
                            </h4>
                            {error && <p>Error: {error}</p>}
                            {showMessage && (
                                <p className="dds__body-2">Retraining of the model is in progress...
                                    An email will be sent once the retraining is completed. Please use the recommendation model post retraining.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div style={{ float: 'right', display: "flex", flexDirection: "row" }}>
                    <BackButton url="/assetinventory/ipss/devicetyperanking" />
                    <div ref={nextButtonRef}>
                        <NextButton url={""} isLast={true} />
                    </div>
                </div>
            </ContentBox>
        </div>
    );
};

export default RunModelPage;
