const PersonaJobFamilyMappingToolbar = ({ AddMeasuresButton }) => {
	return (
		<>
			<div className="dds__w-100">
				<div
					className="dds__w-100 dds__h-100 dds__d-inline-block"
					style={{
						verticalAlign: "middle",
						position: "relative",
					}}
				>
					<div
						className=" dds__position-absolute"
						style={{
							top: "13%",
							right: "1%",

						}}
					><div style={{ marginTop: "45px" }}>
							<span
								className="dds__mr-2">
								<AddMeasuresButton />
							</span>
						</div>
					</div>
				</div>

			</div>
		</>
	);
};

export default PersonaJobFamilyMappingToolbar;
