import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import the validation component

const JobFamily: React.FC<{
    mode: 'new' | 'edit',
    JobFamilyInput: string,
    setJobFamilyInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    JobFamilyInput,
    setJobFamilyInput,
    initialize = false,
}) => {
        // Use the text validation hook
        const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setJobFamilyInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handle focus to show validation state
        const handleFocus = () => {
            setIsFocused(true);
        };

        // Handle blur event
        const handleBlur = () => {
            setIsFocused(false);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="JobFamily-field" className="dds__label">
                    {mode === 'edit' ? 'JobFamily' : 'Job Family '}
                    <span style={{ color: 'red' }}> *</span>
                </label>
                <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: '100%',
                            borderRadius: '1.5rem',
                        }}
                        placeholder=""
                        name="JobFamily-field"
                        id="JobFamily-field"
                        required
                        aria-labelledby="JobFamily-label"
                        onChange={(event) => validateInput(event.target.value)} // Use validateInput from UseTextValidation
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={JobFamilyInput}
                    />
                    {/* Show validation messages based on focus and validity */}
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            Accepts a valid string. {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && JobFamilyInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Accepts a valid string (up to 50 characters).
                        </span>
                    )}
                </div>
            </div>
        );
    };

export default JobFamily;
