import React, { useEffect } from 'react';
import { PageTitle } from '../../Admin/styles';
import { FeaturesWithWeightageTable } from "./FeaturesWithWeightageTable"
import columns from './DataModel/DataModel';
import AddMeasuresButton from './Toolbar/AddMeasuresButton';
import { queryKeys } from '../../../../react-query/constants';
import { TableContextProvider } from './TableContext';
import { getFeaturesConfig } from './FeaturesWithWeightageAPI';
import BackButton from '../BackButton';
import NextButton from '../NextButton';
import ProgressBar from '../Pbar';
import { ContentBox, ProgressBox } from '../IpssPageStyles';

const FeaturesWithWeightagePage: React.FC = () => {
  useEffect(() => {
    document.title = 'Asset Inventory | FeaturesWithWeightage';
  }, []);

  return (
    <div className="dds__container-fluid dds__px-0">
      <PageTitle>PC Selector Criteria</PageTitle>
      <ContentBox>
        <ProgressBox>
          <ProgressBar />
        </ProgressBox>
        <TableContextProvider apiConfig={{ keyName: queryKeys.FeaturesWithWeightageList, getFeaturesConfig }}>
          <FeaturesWithWeightageTable AddMeasuresButton={AddMeasuresButton} columns={columns} />
        </TableContextProvider>
        <div style={{ float: 'right' }}>
          <BackButton url="/assetinventory/ipss/personajobfamilymapping" />
          <NextButton url="/assetinventory/ipss/inventorymodeltrainweightage" isLast={false} />
        </div>
      </ContentBox>
    </div>
  );
};

export default FeaturesWithWeightagePage;

