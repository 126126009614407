import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError, AuthError } from "../../../../ErrorStates";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import { useQueryClient } from "@tanstack/react-query";
import { useUserInfo } from "../../../../store/auth/UserInfoContext";

export const TableContext = createContext<any>(null);

export const TableContextProvider = ({ apiConfig, children }) => {
    const queryClient = useQueryClient();
    //for dynamic url, getting dellID
    const currentUser = useUserInfo();
    const dellID = currentUser.getUserInfo().badgeId
    console.log("here it is:" + dellID);
    const { isLoading, error: fetchError, data: modelConfigData, refetch } = useCustomQuery(
        [apiConfig.keyName],
        apiConfig.getModelConfig(dellID), //check ModelConfigsPage.tsc to understand where and how getModelConfig is sued. passing dellID as parameter here
        {
            select: (data) => {
                return data.data; //returning data.data, hence storing data in modelConfigData.(check api response structure from swagger)
            }
        }
    );

    const [error, setError] = useState<string>();
    const handleError = useErrorHandler(); // Assuming this is a custom hook to handle errors
    const [mappedData, setMappedData] = useState<any[]>([]);

    useEffect(() => {
        refetch()
        if (modelConfigData) {
            // Map modelConfigData to match the expected structure
            const mappedResults = modelConfigData.map((item) => {
                const mappedItem: any = {
                    //map to the keys (from api response, the keys should match the accessors in dataModel.tsx to be properly displayed)
                    id: item.id,
                    configName: item.configName,
                    action: item.action
                };
                return mappedItem;
            });


            setMappedData(mappedResults);
            // console.log("modelConfigData loaded:", modelConfigData);
            //to check you can uncomment the above console.log in future
        }
    }, [modelConfigData]);

    useEffect(() => {
        if (fetchError) {
            console.log("Error on component", fetchError);
            if (fetchError instanceof APIRequestFailedError) {
                if (fetchError.statusCode === "201") {
                    setError("No Record Found");
                } else if (fetchError.statusCode === "204") {
                    setError(fetchError.message);
                } else {
                    handleError(fetchError);
                }
            } else if (fetchError instanceof AuthError) {
                handleError(fetchError);
            } else {
                setError(
                    "Data is not loading at the moment. Please check your connection and try again."
                );
            }
        }
    }, [fetchError, handleError]);

    const handleRefetch = () => {
        refetch(); // Trigger manual refetch of data
    };

    const deleteSuccessHandler = (data) => {
        queryClient.invalidateQueries([apiConfig.keyName]); // Invalidate and refetch data
    };

    // Render children when data is loaded and no error
    return <TableContext.Provider value={{ allData: mappedData, refetchData: handleRefetch, setMappedData }}>
        {(isLoading || error) && <div className="dds__mx-auto dds__text-center" style={{
            marginTop: "calc(100px + 1.5rem)"
        }}>
            {isLoading &&
                <LoadingSpinner label="Fetching Model Configs" />
            }
            {error && <>{error}</>}

        </div>}
        {!isLoading && !error && children}
    </TableContext.Provider>
};
