import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserInfo } from '../../../store/auth/UserInfoContext';
import { useCustomMutation } from '../../../hooks/useCustomQuery';
import { RetrainMeasuresModel } from '../IPSSMeasures/IpssAPI';

const NextButton = ({ url, isLast }) => {
  const history = useHistory();
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const user = useUserInfo();

  const handleNext = () => {

    if (isLast === true) {
      setLoading(true);
      retrainApi(saveObject);
    } else {
      history.push(url);
    }
  };

  const requestBody = {
    email: user.getUserInfo().email,
    userName: user.getUserInfo().userName,
  };

  const saveObject = {
    ...requestBody,
  };

  const saveSuccessHandler = (data: any) => {
    setLoading(false)
  }

  const { mutate: retrainApi, isLoading: isLoading } = useCustomMutation(
    RetrainMeasuresModel(),
    {
      onSuccess: saveSuccessHandler,
      onError: error => {
        console.error(error);
      },
    }
  );

  
  return (
    <button
      aria-label="next"
      role="button"
      onClick={handleNext}
      className="dds__button dds__button--sm dds__button--primary dds__mx-2 dds__mb-2"
    >
      {isLast ? "Retrain the Model" : "Next"}
      <i
        aria-hidden="true"
        className={isLast ? "dds__icon dds__icon--gear dds__button__icon--end" : "dds__icon dds__icon--arrow-right dds__button__icon--end"}
      />
    </button>
  );
};

export default NextButton;
