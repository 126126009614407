// Function to get the entire object from sessionStorage
export const getMyObject = (): { [key: string]: string } => {
    const savedData = localStorage.getItem("PermissionCodes");
    return savedData ? JSON.parse(savedData) : {}; // Default to an empty object if nothing is saved
};

// Function to get a specific value from myObject by key
export const getSpecificValue = (key: string): string | null => {
    const currentObject = getMyObject();  // Get the current saved object from sessionStorage
    return currentObject[key] || null;    // Return the value for the given key, or null if not found
};

// Function to update the entire myObject in sessionStorage
export const setMyObject = (myObject: { [key: string]: string }) => {
    localStorage.setItem("PermissionCodes", JSON.stringify(myObject));
};