import ReactTooltip from "react-tooltip";
import DefaultCell from "../../../../Table/ReusableTableCells/DefaultCell";
import ActionColumn from "./ActionColumn/ActionColumn";
import { IconButtonWrapper } from "../../../../Dashboardv3/Card/Styles";
import { UseTableCellProps } from "react-table";
const columns: any[] = [

	// Use disableSortBy: true - to disable sort. example:
	// { Header: "Medium Threshold", accessor: "medium_threshold",disableSortBy: true,width:20},
	{ Header: "Financial Year", accessor: "financialYear", width: 50, disableSortBy: false },
	{ Header: "Personas", accessor: "persona", width: 50 },
	// { Header: "User Type", accessor: "user_type", width: 50, show: false },
	{ Header: "Device Type", accessor: "deviceType", width: 50 },
	{ Header: "Device Name", accessor: "deviceName", width: 50 },
	{ Header: "Processor", accessor: "processor", width: 50 },
	{ Header: "Memory", accessor: "memoryInGb", width: 50, show: false },
	{ Header: "Storage", accessor: "storage", width: 50, show: false },
	{ Header: "Graphics", accessor: "graphics", width: 50, show: false },
	{ Header: "Thunderbolt", accessor: "thunderbolt", width: 50, show: false },
	{ Header: "Display", accessor: "display", width: 50, show: false },
	{ Header: "Battery", accessor: "battery", width: 50, show: false },
	{ Header: "Connectivity", accessor: "connectivity", width: 50, show: false },
	{ Header: "IsExlusive", accessor: "is_exlusive", width: 50, show: false },
	{ Header: "Actions", Cell: ActionColumn },
	{
		// Header: () => null,
		Header: "measureValues",
		accessor: "measure_Values",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually

	},
	{
		// Header: () => null,
		Header: "id",
		accessor: "id",
		disableSortBy: true,
		// ExpandComponent:false,
		show: false, // Set width to 0 or null to hide it visually

	},
];

export default columns;
