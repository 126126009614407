import React, { useEffect, useState, useRef, useContext } from "react";
import ClientTable from '../../../Common/ClientTable/ClientTable';
import { displayValue } from "@tanstack/react-query-devtools/build/lib/utils";
import "../../../Table/TableStyles.tsx";
import ExpandComponent from "./ExpandComponent.tsx";
import { TableContext } from './TableContext.tsx'
import InventoryModelTrainWeightageToolbar from "./Toolbar/Toolbar.tsx";


export const InventoryModelTrainWeightageTable = ({ columns, AddMeasuresButton }) => {
	const { allData } = useContext(TableContext); // Access allData from TableContext

	return (
		<>
			<InventoryModelTrainWeightageToolbar AddMeasuresButton={AddMeasuresButton} />
			<div className="dds__mt-4 dds__mb-4">
				<ClientTable
					data={allData}
					columns={columns}
					skipStateReset={false} // Adjust as per your requirement
					disableColumnFunctions={false} // Disable column functions if applicable
					disablePagination={false}
					ExpandComponent={ExpandComponent}
				/>
			</div>
		</>
	);
};

export default InventoryModelTrainWeightageTable;
