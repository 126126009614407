import { useEffect, useState } from "react";
import { useCustomQuery } from "../../../hooks/useCustomQuery";
import { queryKeys } from "../../../react-query/constants";
import { assetInventoryMenuListConfig } from "../../AssetInventory/API";
import { inventory } from "../../Dashboardv3/constants";
import { SideNavigation } from "./SideNavigation";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError, AuthError } from "../../../ErrorStates";
const AssetInventorySideNavigaton: React.FC<{
	userType: {
		user: boolean,
		admin: boolean,
		superAdmin: boolean,
		leader: boolean,
		moduleID: string
	},
}> = ({ userType }) => {

	const { isLoading, error: fetchError, data: allData } = useCustomQuery([queryKeys.assetInventorysidemenu], assetInventoryMenuListConfig(userType.moduleID),
		{
			select: (data) => {
				return data.data;
			}
		});

	const [error, setError] = useState<string>()
	const handleError = useErrorHandler();

	useEffect(() => {
		if (fetchError) {
			console.log("error on component", fetchError)
			if (fetchError instanceof APIRequestFailedError) {
				if (fetchError.statusCode === "201")
					setError("No Record Found")
				else if (fetchError.statusCode === "204")
					setError(fetchError.message)
				else
					handleError(fetchError)
			} else if (fetchError instanceof AuthError) {
				handleError(fetchError)
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [fetchError, handleError])


	/* const determineLinks = () => {
		const links = [
			{ show: true, path: '/', name: 'Home', iconClass: 'dds__icon--home', cascadingLink: false },
			{ show: true, path: '/assetinventory', name: 'My Assets', iconClass: 'dds__icon--user', cascadingLink: false },
			{ show: userType.leader || userType.admin || userType.superAdmin, path: '/assetinventory/leaderboard', name: 'Leaderboard', iconClass: 'dds__icon--user-groups', cascadingLink: true },
			{ show: userType.leader, path: '/assetinventory/myorg', name: 'My Organization Assets', iconClass: 'dds__icon--user-groups', cascadingLink: false },
			{ show: userType.admin || userType.superAdmin, path: '/assetinventory/admin', name: 'Administrator', iconClass: 'custom_admin_icon', cascadingLink: false },
			{ show: userType.superAdmin, path: '/assetinventory/manageusers', name: 'Manage Users', iconClass: 'dds__icon--gear-wrench', cascadingLink: false },
			{ show: userType.superAdmin, path: '/assetinventory/telemetrymeasures', name: 'Telemetry Measures', iconClass: 'dds__icon--diagnostic', cascadingLink: false }
		];

		return links.filter(link => link.show);
	} */

	const determineLinks = () => {
		if (!allData) return [];

		const links = allData.map((item: { id: string, name: string }) => {
			let show = true;
			let path = '';
			let iconClass = '';
			let cascadingLink = false;

			switch (item.name) {
				case 'Home':
					path = '/';
					iconClass = 'dds__icon--home';
					cascadingLink = false;
					break;
				case 'My Assets':
					path = '/assetinventory';
					iconClass = 'dds__icon--user';
					cascadingLink = false;
					break;
				case 'Leaderboard':
					show = userType.leader || userType.admin || userType.superAdmin;
					path = '/assetinventory/leaderboard';
					iconClass = 'dds__icon--user-groups';
					cascadingLink = true;
					break;
				case 'My Organization Assets':
					show = userType.leader;
					path = '/assetinventory/myorg';
					iconClass = 'dds__icon--user-groups';
					cascadingLink = false;
					break;
				case 'Administrator':
					show = userType.admin || userType.superAdmin;
					path = '/assetinventory/admin';
					iconClass = 'custom_admin_icon';
					cascadingLink = false;
					break;
				case 'Manage Users':
					show = userType.superAdmin;
					path = '/assetinventory/manageusers';
					iconClass = 'dds__icon--gear-wrench';
					cascadingLink = false;
					break;
				case 'PC Selector Criteria':
					show = userType.superAdmin;
					path = '/assetinventory/ipss/utilizationrules';
					iconClass = 'dds__icon--diagnostic';
					cascadingLink = false;
					break;
				case 'PC Selector Recommendation':
					path = '/assetinventory/assetrecommendation/assetrecommendationutilisation';
					iconClass = 'dds__icon--thumb-up';
					cascadingLink = false;
					break;
				/* 
				case 'PC Selector Bulk Recommendation':
					path = '/assetinventory/pcselectorbulkrecommendation';
					iconClass = 'dds__icon--bulk-recommendation';
					break; */
				default:
					show = false;
			}

			return { show, path, name: item.name, iconClass, cascadingLink };
		});

		return links.filter(link => link.show);
	}

	return (
		<>
			<SideNavigation portalName={inventory} links={determineLinks()} />
		</>)
};

export default AssetInventorySideNavigaton;
