import React, { useCallback, useEffect, useRef, useState } from 'react'
import BoxWrapper from '../../../QuarantinePortal/BoxWrapper'
import { FileInput, Form } from "@dds/components";
import { DDSModal } from "@dds/react";
import ProgressBar from '../../../Common/ProgressBar/ProgressBar';
import "../../../../../node_modules/@dds/react/css/dds-components.css";
import { Category } from './BlockUnblockPageHelper';
import { UploadExcelConfig, downloadTemplateConfig } from '../../API';
import useAxios from '../../../../hooks/useAxios';
import { useCustomMutation } from '../../../../hooks/useCustomQuery';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../hooks/useAuth';

const UploadFileBox: React.FC<{
	category: Category;
}> = ({ category }) => {

	// const [isUploading, setIsUploading] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	// const [uploadError, setUploadError] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const openModal = useCallback(() => setIsOpen(true), []);
	const closeModal = useCallback(() => setIsOpen(false), []);
	const fileInputRef = useRef<HTMLDivElement>(document.getElementById(`fileinput-415316069-${category}`) as HTMLDivElement);
	const fileInputFieldRef = useRef<HTMLInputElement>(null);
	const formRef = useRef<HTMLFormElement>(document.getElementById(`form-808675083-${category}`) as HTMLFormElement);

	const { mutate, isLoading: isUploading, error: uploadError } = useCustomMutation(UploadExcelConfig(category === Category.block ? "Block" : "Unblock"), {})
	const blockText = {
		uploadHeading: "Block Devices - File Upload",
		uploadCaption: "Upload a .XLSX file to Full block multiple devices at once.",
		uploadNote: "Note - The bulk action will work only with Hostnames and for fully blocking devices."
	}
	const unblockText = {
		uploadHeading: "Unblock Devices - File Upload",
		uploadCaption: "Upload a .XLSX file to Full Unblock multiple devices at once.",
		uploadNote: "Note - The bulk action will work only with Hostnames and for fully Unblocking devices."
	}

	const [text, setText] = useState(blockText);
	const auth = useAuth();
	useEffect(() => {
		if (category === Category.block) {
			setText(blockText);
		} else {
			setText(unblockText);
		}
	}, [category]);

	useEffect(() => {
		const element = fileInputRef.current;
		const form = formRef.current;

		const fileInputApi = new FileInput(element, {
			acceptedFileTypes: ".xlsx",
			maxFileCount: 1,
			maxFileSize: 900000,
		});

		// Initializes the Form
		const formApi = Form(form);
		form!.addEventListener("submit", event => {
			event.preventDefault();
			startUpload(fileInputApi);

		});

		return () => {
			fileInputApi.dispose();
		};
	}, []);


	const startUpload = fileInputApi => {
		// Validates the File input using the validate() method before submitting the form.
		if (fileInputApi.validate() && fileInputFieldRef.current && fileInputFieldRef.current.files) {
			if (fileInputFieldRef.current && fileInputFieldRef.current.files) {
				console.log("Uploading file...", fileInputFieldRef.current.files[0]);
			}
			// console.log("Uploading file...", fileInputApi.element.files);
			// return;

			fileInputApi.setState("uploading");
			openModal();
			let progress = 0;
			const interval = setInterval(() => {
				if (progress < 90) progress += 10;
				setUploadProgress(progress);
			}, 1000);
			console.log("Uploading file...", fileInputFieldRef.current.files[0]);
			let fd = new FormData();
			fd.set("file", fileInputFieldRef.current.files[0]);
			for (let pair of fd.entries()) {
				console.log(pair[0] + ', ' + pair[1]);
			}
			mutate(fd, {
				onSuccess: () => {
					setUploadProgress(100);
					clearInterval(interval);
				},
				onError: () => {
					setUploadProgress(0);
					clearInterval(interval);
				}
			})
			// setIsUploading(true);
			// Simulating the file upload
			// increase upload progress from 0 to 100 over a period of 10s

			openModal();
			// open DDS Modal with a progress bar
		} else {

		}

	};
	const { sendRequest } = useAxios();

	const downloadTemplate = async (e) => {
		e.preventDefault();
		const token = await auth.getAccessToken();
		const URL = downloadTemplateConfig().url;
		const apikey = process.env.REACT_APP_L7_APIKEY;
		//sendRequest(downloadTemplateConfig(), (data) => console.log(data, "link"));
		// Fetch the Excel file with .xls extension
		fetch(downloadTemplateConfig().url, {
			method: 'GET',
			headers: URL.toLowerCase().includes(".anp2.pcf.dell.com") ? {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token!.replace(/\n|\r/g, "")}`, // added for PCF URLs

			} as HeadersInit : {
				'Content-Type': 'application/json',
				jwt_token: `${token!.replace(/\n|\r/g, "")}`, // For L7 URLs
				apikey: apikey, // For L7 URLs
			} as HeadersInit,
		})
			.then((response) => response.blob())
			.then((blob) => {
				// Create a blob URL and create a link element to trigger the download
				const blobUrl = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.style.display = 'none';
				a.href = blobUrl;
				a.download = 'SampleQuarantineExcelSheet.xlsx'; // Set the desired file name with .xls extension
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(blobUrl);
			})
			.catch((error) => {
				console.error('Error fetching Excel file:', error);
			});
	}


	return (
		<>
			<BoxWrapper
				color="var(--blue-200)"
				style={{
					flex: 2,
					minWidth: 300,
					minHeight: 224,
				}}
			>
				<h4>{text.uploadHeading}</h4>
				<form
					ref={formRef}
					id={`form-808675083-${category}`}
					data-dds="form"
					className="dds__form"
					noValidate={true}
				>
					<div
						ref={fileInputRef}
						id={`fileinput-415316069-${category}`}
						className="dds__file-input"
						data-dds="file-input"
						role="group"
						aria-labelledby={`file-input-label-953559583-${category}`}
					>
						<label
							id={`file-input-label-953559583-${category}`}
							htmlFor={`file-input-control-name-953559583-${category}`}
							className="dds__label dds__label--required"
						>
							{text.uploadCaption}
						</label>
						<small
							id={`file-input-helper-953559583-${category}`}
							className="dds__file-input__helper-text"
						>
							File format: .xlsx
							<a href='#' onClick={downloadTemplate} className="dds__link dds__ml-1">
								Download sample file
							</a>
						</small>
						<small
							id={`file-input-label-953559583-${category}`}
							className="dds__file-input__helper-text"
						>
							{text.uploadNote}
						</small>
						<input
							tabIndex={-1}
							aria-hidden="true"
							id={`file-input-control-953559583-${category}`}
							type="file"
							name={`file-input-control-name-953559583-${category}`}
							className="dds__file-input__control"
							multiple={true}
							required={true}
							ref={fileInputFieldRef}
						/>
						<button
							className="dds__button dds__button--secondary dds__button--md dds__file-input__button"
							type="button"
							aria-describedby={`file-input-helper-953559583-${category}`}
						>
							<span
								className="dds__icon dds__icon--upload dds__button__icon--start"
								aria-hidden="true"
							></span>
							<span>Browse Files</span>
						</button>
						<div
							id={`file-input-error-953559583-${category}`}
							aria-live="polite"
							className="dds__error-text"
						>
							<span
								className="dds__icon dds__icon--alert-error dds__error-text__icon"
								aria-hidden="true"
							></span>
							Something went wrong
							{/* TODO: Replace with proper errors */}
						</div>
					</div>
					<div className="dds__d-flex dds__justify-content-center dds__mt-3">
						<button className="dds__button dds__form__submit" type="submit">
							Upload
						</button>
					</div>
				</form>
			</BoxWrapper>
			<DDSModal
				size={"md"}
				onClose={isUploading ? () => { } : closeModal}
				open={isOpen}
				title="File Uploading"
			>
				{isUploading && (
					<ProgressBar
						bgColor="var(--gray-100)"
						textColor="white"
						progress={uploadProgress}
					></ProgressBar>
				)}
				{!isUploading && !uploadError && (
					<p>
						File uploaded successfully. You can check the status at{" "}
						<Link to="/quarantine-portal/bulk-upload-logs">Upload Logs</Link>
					</p>
				)}
				{!isUploading && uploadError && (
					<p>
						Something went wrong during upload.
					</p>
				)}
			</DDSModal>
		</>
	)
}

export default UploadFileBox