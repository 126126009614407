import React, { useEffect, useState, useContext } from "react";
import DDSModal from "../../../../Common/Modalv2/DDSModal";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { useAlert } from "react-alert";
// import TelemetryMeasuresBox from "./ModalForm/InputFields/TelemetryMeasuresBox";
// import SubMeasuresBox from "./ModalForm/InputFields/SubMeasureBox";
import ConfigsName from "./ModalForm/InputFields/ConfigName";
// import MediumThreshold from "./ModalForm/InputFields/MediumThreshold";
import ActionCol from "./ModalForm/InputFields/Action";
// import MeasureValues from "./ModalForm/InputFields/MeasureValue";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import "./FormStyles.css";
import { createModelConfigs, updateModelConfigs } from '../ModelConfigsAPI';
import { useQueryClient } from "@tanstack/react-query";
import { TableContext } from "../TableContext";

const EditMeasuresModal: React.FC<{
	mode: "new" | "edit";
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	MeasuresDetails?: {
		action: string;
		configName: string;
		// low_threshold: string;
		// medium_threshold: string;
		// high_threshold: string;
		// measure_Values: string;
		id: number;
	};
}> = ({ mode, modalInstanceRef, MeasuresDetails, handleClose }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData, setMappedData } = useContext(TableContext);

	const [MeasureInput, setMeasureInput] = useState(
		MeasuresDetails?.configName || ""
	);
	const [ActionColInput, setActionColInput] = useState(
		MeasuresDetails?.action || ""
	);

	// Function to sanitize threshold values
	const sanitizeThreshold = (value: string | undefined) => {
		if (!value) return '';
		return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
	};
	const saveSuccessHandler = (data: any) => {

		modalInstanceRef.current.close();

		refetchData();
		alert.show("Saving...", {
			type: "success",
			timeout: 1000,
		});
		// Wait for 1 seconds before showing the alert
		setTimeout(() => {
			alert.show("Saved", {
				type: "success",
				timeout: 5000,
			});
		}, 1000);
	};

	const { mutate: saveAPI, isLoading: isSaving } = useCustomMutation(
		createModelConfigs(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while saving, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const { mutate: updateAPI, isLoading: isUpdating } = useCustomMutation(
		updateModelConfigs(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while updating, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const updateDetails = () => {


		const requestBody = {
			configName: MeasureInput,
			action: ActionColInput,
			id: MeasuresDetails?.id,
		};

		const saveObject = {
			...requestBody,
		};

		if (mode === "new") {
			saveAPI(saveObject);
		} else {
			updateAPI(saveObject);
		}
	};



	const CancelDetails = () => {
		modalInstanceRef.current.close();
	};

	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isSaving || isUpdating) {
			setShowError(false);
		} else {
			setShowError(!(ActionColInput && MeasureInput));
		}
	}, [isSaving, isUpdating, ActionColInput, MeasureInput]);

	return (
		<DDSModal
			themeColor={"white"}
			iconClass={"dds__icon--diagnostic"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__rounded dds__modal--lg"}
			id={
				mode === "new"
					? "AddMeasures"
					: MeasuresDetails?.action || ""
			}
			title={mode === "new" ? "Add Measures - Model Configs" : "Edit Measures"}
			disposeOnClose={true}
			footerButtons={[
				{
					class: "dds__button--secondary",
					text: "Cancel",
					handleFooterButtonClick: CancelDetails,
					disabled: (isSaving || isUpdating),
				},
				{
					class: "dds__button--primary",
					text: (mode === "edit") ? "Edit measures" : "Add measures",
					handleFooterButtonClick: updateDetails,
					disabled: (showError || (isSaving || isUpdating)),
				},
			]}
			onClose={handleClose}
		>
			<>
				{!(isSaving || isUpdating) && (
					<>
						<div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "1rem", transform: "scale(0.9)" }}>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<ConfigsName
										mode={mode}
										MeasureInput={MeasureInput}
										setMeasureInput={setMeasureInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<ActionCol
										mode={mode}
										ActionColInput={ActionColInput}
										setActionColInput={setActionColInput}
										initialize={mode === "new"}
									/>
								</div>
							</div>
						</div>

					</>
				)}
				<br />
				{(isSaving || isUpdating) && (
					<LoadingSpinner size="sm" label="Saving" />
				)}

			</>
		</DDSModal>
	);
};

export default EditMeasuresModal;