import React, { useState } from 'react';
import UseNumberValidation from '../../../../ValidationRules/UseNumbervalidation'; // Import the validation component

const FeatureWeightage: React.FC<{
    mode: 'new' | 'edit',
    FeatureWeightageInput: string,
    setFeatureWeightageInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    FeatureWeightageInput,
    setFeatureWeightageInput,
    initialize = false,
}) => {
        const { isValid, errorMessage, validateInput } = UseNumberValidation(setFeatureWeightageInput);
        const [isFocused, setIsFocused] = useState(false);

        const handleBlur = () => {
            setIsFocused(false);
            if (FeatureWeightageInput) {
                const roundedValue = FeatureWeightageInput.includes('.') ? parseFloat(FeatureWeightageInput).toFixed(2) : FeatureWeightageInput;
                setFeatureWeightageInput(roundedValue);
            }
        };

        const handleFocus = () => {
            setIsFocused(true);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="FeatureWeightage-field" className="dds__label">
                    {mode === 'edit' ? 'FeatureWeightage' : 'Feature Weightage'}
                    <span style={{ color: 'red' }}> *</span>
                </label>
                <div className="dds__input-text__wrapper">
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: "100%",
                            borderRadius: "1.5rem",
                            outline: 'none'
                        }}
                        name="FeatureWeightage-field"
                        id="FeatureWeightage-field"
                        required
                        aria-labelledby="FeatureWeightage-label"
                        onChange={(event) => validateInput(event.target.value)} // Use validateInput from UseNumberValidation
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={FeatureWeightageInput}
                    />
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            Accepts a valid number. {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && FeatureWeightageInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Accepts a valid number (up to 2 decimal places).
                        </span>
                    )}
                </div>
            </div>
        );
    };

export default FeatureWeightage;
