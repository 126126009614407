import { ColumnInstance } from "react-table";

const ColumnShowAdjust: React.FC<{
	allColumns: ColumnInstance<Object>[];
}> = props => (
	<>
		{props.allColumns.map(column => {
			if ((column.id !== "expander") && (column.Header !== "id") && (column.Header !== "measureValues"))
				return (
					<span key={column.id}>
						<label style={{ margin: "10px" }}>
							<input name={column.Header} type="checkbox" {...column.getToggleHiddenProps()} />{" "}
							{column.Header}
							{/* {console.log(column)} */}
						</label>
					</span>
				);
			return null;
		})}
	</>
);
export default ColumnShowAdjust;
