import React, { useState } from 'react'
import Modal from '../../../Common/Modal/Modal'
import ModalHeader from './ModalHeader';
import { useRegions } from '../ManageUsersPage/RegionContext';
import ModalForm from './ModalForm/ModalForm';
import { accessDetailsType } from '../DataModel/ActionColumn/EditAction';

const EditAdminModal: React.FC<{
	handleClose: () => void,
	mode: 'edit' | 'new',
	permissionData?: any,
	moduleId?: number,
	employeeDetails?: {
		admname: string,
		email: string,
		rolename: string,
		accesstype: string,
		roleid: number,
		ntid: string,
		badgeId: number
	},
	accessDetails?: accessDetailsType
}> = ({ handleClose, mode, employeeDetails, permissionData, moduleId, accessDetails }) => {

	const [overflow, setOverflow] = useState<"auto" | "visible">("auto")

	const { resetRegion } = useRegions();

	const handleCloseModifier = () => {
		// console.log(isSaving)
		// if (isSaving) {
		// 	console.log("here")
		// }
		// else
		resetRegion()
		handleClose()
	}

	return (
		<Modal onClose={handleCloseModifier} style={{
			overflowY: overflow,
			height: 'auto',
			// height: overflow === 'auto' ? 'auto' : '100%',
		}}>
			{/* Modal Header */}
			<div className="dds__position-fixed dds__w-100" style={{
				top: 0,
			}}>
				<ModalHeader mode={mode} handleClose={handleCloseModifier} />
			</div>

			<ModalForm mode={mode} permissionData={permissionData} moduleId={moduleId} overflow={overflow} handleClose={handleCloseModifier}
				employeeDetails={employeeDetails} accessDetails={accessDetails} />

		</Modal >
	)
}

export default EditAdminModal;