import React, { useState } from 'react';
import Use0And1Validation from '../../../../ValidationRules/Use0And1Validation'; // Import the validation module

const IsUsed: React.FC<{
  mode: 'new' | 'edit';
  MeasureInput: string;
  setMeasureInput: React.Dispatch<React.SetStateAction<string>>;
  initialize?: boolean;
}> = ({ mode, MeasureInput, setMeasureInput, initialize = false }) => {
  // Use the 0 and 1 validation hook
  const { isValid, errorMessage, validateInput, setIsValid } = Use0And1Validation(setMeasureInput);
  const [isFocused, setIsFocused] = useState(false);

  // Handle focus to show validation state
  const handleFocus = () => {
    setIsFocused(true);
  };

  // Handle blur event
  const handleBlur = () => {
    setIsFocused(false);
    if (MeasureInput) {
      validateInput(MeasureInput); // Validate input on blur
    }
  };

  return (
    <div className="dds__input-text__container">
      <label htmlFor="Measure-field" className="dds__label">
        {mode === 'edit' ? 'Is Used' : 'Is Used'}
        {mode === 'new' ? <span style={{ color: 'red' }}> *</span> : ""}
      </label>
      <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
        <input
          type="text"
          className="dds__input-text"
          style={{
            width: '100%',
            borderRadius: '1.5rem',
          }}
          placeholder=""
          maxLength={1} // Limit to 1 character since we're only allowing '0' or '1'
          name="Measure-field"
          id="Measure-field"
          required
          aria-labelledby="Measure-label"
          onChange={(event) => validateInput(event.target.value)} // Validate on input change
          onBlur={handleBlur} // Validate on blur
          onFocus={handleFocus} // Handle focus
          value={MeasureInput}
        />
        {/* Show validation messages based on focus and validity */}
        {isFocused && !isValid && (
          <span style={{ color: 'red', fontSize: '12px' }}>
            {errorMessage}
          </span>
        )}
        {isFocused && isValid && MeasureInput && (
          <span style={{ color: 'green', fontSize: '12px' }}>
            Valid input: {MeasureInput}
          </span>
        )}
      </div>
    </div>
  );
};

export default IsUsed;
