import { useEffect, useRef } from "react"
import DropdownComponent from "../../../../../Common/DropdownComponent"
import { Dropdown } from "@dds/components"

const RoleDropdown = ({ handleRoleChange, role, checkDropdown = (id: string) => { }, disabled, initialize = false }) => {
	let dropdownRef = useRef<any>();
	let dropdownInstanceRef = useRef<any>();
	useEffect(() => {
		console.log("Role dropdown init", role)
		// Initialize Dropdown role-dropdown
		if (initialize) {
			console.log("Role dropdown init", dropdownRef.current)
			dropdownInstanceRef = new Dropdown(dropdownRef.current);
		}
		return () => {
			if (dropdownInstanceRef.current)
				dropdownInstanceRef.current.dispose();
		}
	}, [])

	return (
		<DropdownComponent
			elementRef={dropdownRef}
			label="Role *"
			onInputClick={() => checkDropdown(`role-dropdown-popup`)}
			PopupList={
				<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
					<li className="dds__dropdown__item" role="none" key="admin">
						<button onClick={() => handleRoleChange({
							rolename: "admin",
							roleid: 1002
						})} disabled={disabled} className="dds__dropdown__item-option" role="option" data-selected={role.rolename === "admin" ? true : false} data-value="admin" tabIndex={role.rolename === "admin" ? 1 : -1}>
							<span className="dds__dropdown__item-label">Admin</span>
						</button>
					</li>
					<li className="dds__dropdown__item" role="none" key="superadmin">
						<button onClick={() => handleRoleChange({
							rolename: "superadmin",
							roleid: 1003
						})} disabled={disabled} className="dds__dropdown__item-option" role="option" data-selected={role.rolename === "super_admin" ? true : false} data-value="super_admin" tabIndex={role.rolename === "superadmin" ? 1 : -1}>
							<span className="dds__dropdown__item-label">Superadmin</span>
						</button>
					</li>
				</ul>}
		/>
	)
	return <></>
}

export default RoleDropdown
