import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { matchSorter } from "match-sorter"
import { APIRequestFailedError, AuthError } from "../../../../ErrorStates";
import { getAdminsConfig } from "../../API";
import columns from "../DataModel/DataModel";
import { dummyData } from "./dummyData";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../react-query/constants";
import LoadingSpinner from "../../../Common/LoadingSpinner";


export const ManageUsersContext = createContext<any>(null);

export const ManageUsersContextProvider = ({ apiConfig, children }) => {
	// Change to API format, once API gets fixed with required data values

	// type dataType = {
	// 	employeeName: string;
	// 	asset_number: string;
	// 	emailId: string;
	// 	country: string;
	// 	stockroom: string;
	// 	role: string;
	// 	status: string;
	// 	action: null;
	// }[]
	//console.log("apiConfig", apiConfig);
	const { isLoading, error: fetchError, data: allData } = useCustomQuery([apiConfig.keyName], apiConfig.getAdminsConfig,
		{
			select: (data) => {
				console.log("data", data)
				//return data.admins;
				// return dummyData
				return data.data;
			}
		});

	// const [allData, setAllData] = useState<dataType>([]);
	const [error, setError] = useState<string>();
	const handleError = useErrorHandler();
	const [filteredData, setFilteredData] = useState<any>([])


	const filterData = (searchText: string) => {
		console.log("Filtering")
		if (searchText !== "") {
			setFilteredData(
				matchSorter(allData, searchText, {
					keys: columns.map((col) => {
						return col.accessor
					}),
				})
			)
		} else setFilteredData(allData)
	}

	useEffect(() => {
		// if (allData["records_found"] === 0) {
		// 	setError("No Record Found")
		// 	return
		// }
		if (allData)
			setFilteredData(allData);
	}, [allData])

	useEffect(() => {
		if (fetchError) {
			console.log("error on component", fetchError)
			if (fetchError instanceof APIRequestFailedError) {
				if (fetchError.statusCode === "201")
					setError("No Record Found")
				else if (fetchError.statusCode === "204")
					setError(fetchError.message)
				else
					handleError(fetchError)
			} else if (fetchError instanceof AuthError) {
				handleError(fetchError)
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [fetchError, handleError])


	return <ManageUsersContext.Provider value={{ filteredData, allData, filterData }}>
		{(isLoading || error) && <div className="dds__mx-auto dds__text-center" style={{
			marginTop: "calc(100px + 1.5rem)"
		}}>
			{isLoading &&
				<LoadingSpinner label="Fetching Admins" />
			}
			{error && <>{error}</>}

		</div>}
		{!isLoading && !error && children}
	</ManageUsersContext.Provider>
}