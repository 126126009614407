import { useState } from 'react';

const UseNumberValidation = (setInput: React.Dispatch<React.SetStateAction<string>>) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const MAX_LIMIT = Math.floor(Number.MAX_SAFE_INTEGER / 10);

    const validateInput = (value: string) => {
        const regex = /^\d*\.?\d{0,2}$/;

        if (value === '') {
            setInput(value);
            setIsValid(true);
            setErrorMessage('');
        } else if (!regex.test(value)) {
            setIsValid(false);
            setErrorMessage(isNaN(Number(value)) ? 'Input must be a number.' : 'Accepts up to 2 decimal places.');
        } else {
            const numericValue = parseFloat(value);
            if (numericValue > MAX_LIMIT) {
                setIsValid(false);
                setErrorMessage(`Largest allowed numeric value reached`);
            } else {
                setInput(value);
                setIsValid(true);
                setErrorMessage('');
            }
        }
    };

    return { isValid, errorMessage, validateInput, setIsValid };
};

export default UseNumberValidation;
