import { useQueryClient } from "@tanstack/react-query";
import { useAlert } from "react-alert";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../react-query/constants";
import { updateCreateAdminConfig } from "../../../API";

const useSaveData = (mode, handleClose, moduleId) => {
	const queryClient = useQueryClient();

	// Save success
	const saveSuccessHandler = () => {
		alert.show("Saved", {
			type: "success",
			timeout: 5000,
		});
		handleClose();
		queryClient.invalidateQueries([queryKeys.inventoryadminlist]);
	};

	console.log("moduleId", moduleId);

	//To Save data
	const {
		isLoading: isSaving,
		error: saveError,
		isError: isSaveError,
		mutate: saveAPI,
	} = useCustomMutation(updateCreateAdminConfig(mode, moduleId), {
		onSuccess: saveSuccessHandler,
		onError: error => {
			console.error(error.response.data.message);
			alert.show(
				error.response.data.message
					? error.response.data.message
					: "Unknown error occurred while saving, please try again later",
				{
					type: "error",
					timeout: 5000,
				}
			);
		},
	});

	const alert = useAlert();
	// Function to call save API with all the data
	const saveData = ({ mode, regions, selectedItems, ...props }) => {
		let temp: any[] = [];
		let stockrooms: {
			country: string;
			stockroom: string;
		}[] = [];
		regions.forEach(region => {
			if (region.selected !== -1) {
				temp = [];
				console.log(region.country);
				region.stockrooms.forEach(stockroom => {
					if (stockroom.selected) {
						stockrooms.push({
							stockroom: stockroom.stockroom,
							country: stockroom.country,
						});
						temp.push(stockroom.name);
					}
				});
				console.log(temp);
			}
		});
		//if (stockrooms.length > 0) {  // Todo: commenting temporary if else condition
		const body = {
			...props,
			stockrooms,
		};

		// Todo: remove the temporary body for next release
		const temporarybody = {
			role: props.rolename === "admin" ? "ADMIN" : "SUPER_ADMIN",
			permissionId: selectedItems,
			userId: props.badgeId,
			groupType: "",
			location: [
				{
					locationName: "India",
					subLocation: [
						"Dell India TEMP",
						"Dell India Gurgaon FCTY",
						"Dell India Bangalore-KA-Office 08 FCTY",
						"Dell India Gurgaon",
						"Dell India Bangalore-KA-Office 08",
						"Dell India Excessed",
						"Dell India Bangalore-EMC-INPN6 FCTY",
						"Dell India KA Bangalore Office RSA",
						"Dell India Bangalore-KA-Office 09",
						"Dell India Chennai-TN-ICC",
						"Dell India Bangalore-KA-Office 11",
						"Dell India Hyderabad-AP-Office 01",
						"Dell India Non-Standard Devices",
						"Dell India Bangalore-KA-Office 05",
						"Dell India Chennai-TN FCTY",
						"Dell India KA Bangalore Office 11_Delete",
						"Dell India Chennai-TN-Office 08",
						"Dell India Hyderabad-AP-Office 01 FCTY",
						"Dell India Bangalore-KA-Office 04 FCTY",
						"Dell India Bangalore-KA-Office 10 FCTY",
						"Dell India Chennai-TN-ICC",
						"Dell India Bangalore-KA-Office 10",
						"Dell India Bangalore-EMC-INBA1",
						"Dell India Chennai-TN MFG",
						"Dell India Gurgaon-EMC",
						"Dell India Bangalore-EMC-INBA1 FCTY",
						"Dell India Bangalore-KA-Office 05 FCTY",
						"Dell India Bangalore-EMC-INPN6",
						"Dell India Hyderabad-AP-Office 01",
						"Dell India Hyderabad-AP-Office 01",
						"Dell India Bangalore-KA-Office 09 FCTY",
						"Dell India KA Bangalore Office 11_Remove",
						"Dell India Bangalore-KA-Office 11 FCTY",
						"Dell India Hyderabad-AP-Office 01",
						"Dell India Bangalore-KA-Office 04",
					],
				},
				{
					locationName: "United States",
					subLocation: [
						"Dell NAMER Austin Provisioning RSA",
						"Dell NAMER McLean-EMC",
						"Dell NAMER Lisle-IL FCTY",
						"Dell NAMER Oklahoma City-OK FCTY",
						"Dell NAMER TEMP",
						"Dell NAMER Non WCA Systems",
						"DELL NAMER Connected Provisioning",
						"Dell NAMER Santa Clara-CA FCTY",
						"Dell NAMER APEX MFG",
						"Dell NAMER Atlanta-GA-L-Dell",
						"Dell NAMER Eden Prairie-MN FCTY",
						"Dell NAMER Hopkinton 228 loaner stockroom",
						"Dell NAMER EXEC Hopkinton Loaner",
						"Dell NAMER Durham-NC-EMC",
						"Dell NAMER Landfill",
						"Dell TEMP Non-Standard Devices",
						"Dell NAMER Seattle-WA-EMC",
						"Dell NAMER Franklin FCTY",
						"Dell APJ Sever Systems Tag Num",
						"Dell NAMER EMC New York FCTY",
						"Dell NAMER Santa Clara-CA",
						"Dell NAMER AUSTINWCA",
						"Dell NAMER Round Rock-TX-Austin FCTY",
						"Dell NAMER Round Rock-TX-Austin Provisioning-L-EMC",
						"Dell NAMER Nashville-TN",
						"Dell NAMER Warren-NJ-EMC",
						"Dell NAMER Nashville-TN MFG",
						"Dell NAMER Nashville-TN FCTY",
						"Dell NAMER Franklin",
						"Dell NAMER Franklin RSA",
						"Dell NAMER McLean FCTY",
						"Dell NAMER Warren-NJ FCTY",
						"Software License Pool",
						"Dell NAMER Round Rock-TX-Austin-WCA",
						"Dell NAMER Hopkinton 176 Tech Central Loaner",
						"Dell NAMER Atlanta (L-EMC)-DO NOT USE",
						"Dell NAMER Round Rock-TX-Austin-WCA",
						"Dell NAMER Excessed",
						"Dell NAMER Franklin 50 Loaner Stockroom",
						"Dell NAMER Santa Clara-CA-EMC",
						"Dell NAMER Seattle-WA FCTY",
						"Dell NAMER Franklin (L-EMC)",
						"Dell NAMER Bedford Loaner Stockroom",
						"Dell NAMER Lisle-IL-EMC",
						"Dell NAMER Franklin MFG",
						"Dell NAMER Durham-NC FCTY",
						"Dell NAMER Legal Hold Karen ",
						"Dell NAMER Richardson FCTY",
						"Dell NAMER Austin RR1 Onsite",
						"Dell NAMER Hopkinton 171 Loaner Stockroom",
						"Dell NAMER Eden Prairie-MN",
						"Dell NAMER Richardson-EMC",
						"Dell NAMER Draper-EMC",
						"Dell NAMER Draper FCTY",
						"Dell NAMER Atlanta-GA FCTY",
						"Dell NAMER EMC New York",
						"Dell NAMER Atlanta-GA-L-EMC",
						"Dell NAMER RSA",
						"Dell NAMER Server Storage Network Tags",
						"Dell NAMER Non-Standard Devices",
						"Dell NAMER Oklahoma City-OK",
						"Dell NAMER Research Triangle Park-NC-EMC",
						"Dell NAMER NorCal Exec",
					],
				},
			],
			group: [
				{
					groupId: 0,
					name: "string",
				},
			],
		};
		console.log(body);
		saveAPI(temporarybody);
		/* } else {
			alert.show("Please select at least one stockroom to proceed", {
				type: "error",
				timeout: 5000,
			});
		} */
	};

	return { isSaving, saveData };
};

export default useSaveData;
