import React from 'react';

const Legend: React.FC = () => {
  return (
    <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px', color: '#333' }}>
      <div style={{ marginBottom: '0.5rem' }}>
        <span style={{ color: '#ff0000' }}>⬤</span> Above Threshold
      </div>
      <div style={{ marginBottom: '0.5rem' }}>
        <span style={{ color: '#008000' }}>⬤</span> Within Threshold
      </div>
      <div style={{ marginBottom: '0.5rem' }}>
        <span style={{ color: '#ffa500' }}>⬤</span> Below Threshold
      </div>
    </div>
  );
};

export default Legend;