
import React, { useEffect, useRef, useState } from 'react'
import DDSModal from '../../../Common/Modalv2/DDSModal';
import SearchUserBox from '../../../AssetInventory/ManageUsers/AdminModal/ModalForm/InputFields/SearchUserBox';
import RoleDropdown from '../../../AssetInventory/ManageUsers/AdminModal/ModalForm/InputFields/RoleDropdown';
import { useUserInfo } from '../../../../store/auth/UserInfoContext';
import { useAlert } from 'react-alert';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../../react-query/constants';
import { useCustomMutation } from '../../../../hooks/useCustomQuery';
import { createARTAdmin, updateARTAdmin } from '../../API/api';
import LoadingSpinner from '../../../Common/LoadingSpinner';
import SiteDropdown from './SiteDropdown';

const CreateEditAdminModal: React.FC<{
	mode: 'new' | 'edit';
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	employeeDetails?: {
		ntid: string,
		name: string,
		email: string,
		badgeid: string
		admin: '0' | '1',
		superadmin: '0' | '1',
	};
	accessDetails?: {
		dellSites: {
			siteName: string,
			isDefault: boolean
		}[]
	}
}> = ({ mode, modalInstanceRef, employeeDetails, accessDetails, handleClose }) => {

	const availableDellSites = useUserInfo().getUserInfo().userRole.arrDellSites;
	const alert = useAlert();

	console.log('Access Details', accessDetails)
	console.log('Employee Details', employeeDetails)

	const selectedDellSites = useRef<{
		siteName: string,
		isSelected: boolean
	}[]>([])

	useEffect(() => {
		console.log("Selected dell sites changed to.", selectedDellSites)
	}, [selectedDellSites])

	const dropdownInstanceRef = useRef<any>(null);

	const queryClient = useQueryClient()
	// Save success
	const saveSuccessHandler = (data) => {
		alert.show("Saved", {
			type: "success",
			timeout: 5000,
		});
		modalInstanceRef.current.close();
		queryClient.setQueryData([queryKeys.ARTAdminList], data)
		// queryClient.resetQueries([queryKeys.ARTAdminList]) // Todo: change to invalidate

	};

	//To Save data
	const {
		isLoading: isUpdating,
		mutate: updateAPI,
	} = useCustomMutation(updateARTAdmin(), {
		onSuccess: saveSuccessHandler,
		onError: error => {
			console.error(error);
			alert.show("Error Occurred while saving, please try again", {
				type: "error",
				timeout: 5000,
			});
		},

	});
	//To Save data
	const {
		isLoading: isSaving,
		mutate: saveAPI,
	} = useCustomMutation(createARTAdmin(), {
		onSuccess: saveSuccessHandler,
		onError: error => {
			console.error(error);
			alert.show("Error Occurred while saving, please try again", {
				type: "error",
				timeout: 5000,
			});
		},

	});


	useEffect(() => {
		let temp: any[] = [];
		availableDellSites.forEach(site => {
			let flag = 0;
			if (accessDetails)
				accessDetails.dellSites.forEach(element => {
					if (element.siteName === site.siteName) {
						flag = 1;
					}
				});
			temp.push({
				siteName: site.siteName,
				isSelected: flag == 1 ? true : false
			})

		})
		selectedDellSites.current = temp;

	}, []);
	type extraEmployeeDetails = {
		admname: string;
		accesstype: string;
		ntid: string
		badgeId: string
	};
	const [searchInput, setSearchInput] = useState("");
	const [extraEmployeeInformation, setExtraEmployeeInformation] =
		useState<extraEmployeeDetails>({} as extraEmployeeDetails)
	const [disableSearch, setDisableSearch] = useState(false);
	type roleType = {
		rolename: string,
		roleid: number,
		admin?: '0' | '1',
		superadmin?: '0' | '1'
	}
	const [role, setRole] = useState<roleType>({} as roleType);
	// Role change handler
	const handleRoleChange = (role): void => {
		setRole(role);
	};
	const disabled = useRef<boolean>(false);
	const userNTID = useUserInfo().getUserInfo().ntId; 4
	const loadData = (employeeDetails, accessDetails) => {
		if (employeeDetails) {
			setSearchInput(employeeDetails.email);
			setDisableSearch(true);
			setRole({
				rolename: employeeDetails.admin === '1' ? 'admin' : 'superadmin',
				roleid: 1,
				admin: employeeDetails.admin,
				superadmin: employeeDetails.superadmin,
			});
			disabled.current = true;
			// setExtraEmployeeInformation({
			// 	admname: employeeDetails.admname,
			// 	accesstype: employeeDetails.accesstype,
			// 	ntid: employeeDetails.ntid
			// })
		}

	}

	useEffect(() => {

		if (mode === 'edit') {
			loadData(employeeDetails, accessDetails)
			disabled.current = (userNTID + "").toLowerCase() === (employeeDetails!['ntid'] + "").toLowerCase()
		} else {
			setRole({
				rolename: 'admin',
				roleid: 1
			});
		}
	}, [])

	const updateDetails = () => {
		const dellSites = dropdownInstanceRef.current.getSelection();
		console.log(employeeDetails, " ", selectedDellSites, ' ', role)
		let dellSitesmod: any[] = [];
		dellSites.forEach((site, index) => {
			dellSitesmod.push({
				siteName: site,
				isDefault: index === 0 ? true : false
			})
		});
		const saveObject = {
			admin: role.rolename === 'admin' ? 1 : 0,
			superadmin: role.rolename === 'admin' ? 0 : 1,
			ntid: mode === 'new' ? extraEmployeeInformation.ntid : employeeDetails!.ntid,
			dellSites: dellSitesmod
		}
		console.log("saveObject", saveObject)
		if (role.rolename && dellSitesmod.length > 0 && saveObject.ntid) {
			if (mode === 'new')
				saveAPI(saveObject)
			else
				updateAPI(saveObject)
		} else {
			setShowError(true)
			// alert.show("Please fill all the required fields", {
			// 	type: "error",
			// 	timeout: 2000,
			// });
		}
	}
	// const [test, setTest] = useState<string>('');
	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			setShowError(false)
		}, 4000)
	}, [showError])

	return (
		<DDSModal
			themeColor={'white'}
			iconClass={'icon--manageUsers'}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__rounded dds__modal--lg"}
			id={mode === 'new' ? 'Create Admin' : employeeDetails!.ntid}
			title={mode === 'new' ? 'Create Admin' : 'Edit Admin'}
			disposeOnClose={true}
			footerButtons={[{
				class: '',
				text: 'Save',
				handleFooterButtonClick: updateDetails
			}]}
			onClose={handleClose}
		>
			<div className="dds__container" style={{
				height: '250px'
			}}>
				<div data-dds="form" id='form-id' className="dds__form" onChange={e => e.preventDefault()}>
					{!(isSaving || isUpdating) && <fieldset className="dds__form__section">
						<div className="dds__row">
							<div className="dds__col--1 dds__col--sm-3">
								<div className="dds__form__field">
									<div className="dds__input-text__container">
										<SearchUserBox mode={mode}
											searchInput={searchInput}
											setSearchInput={setSearchInput}
											setExtraEmployeeInformation={setExtraEmployeeInformation}
											disableSearch={disableSearch}
											setDisableSearch={setDisableSearch}
											initialize={mode === 'new'} />
									</div>
								</div>
							</div>
							<div className="dds__col--1 dds__col--sm-3">
								<div className="dds__form__field">
									<div className="dds__input-text__container">
										{(role.rolename) && <RoleDropdown
											handleRoleChange={handleRoleChange}
											role={role}
											disabled={disabled.current}
											initialize={true}
										/>}
									</div>
								</div>
							</div>
						</div>
						<div className="dds__row">
							<div className="dds__col--1 dds__col--sm-6">
								<div className="dds__form__field">
									<div className="dds__input-text__container">
										<SiteDropdown id={mode === 'new' ? 'new' : employeeDetails!.ntid} selectedDellSites={selectedDellSites}
											initialize={true}
											instanceRef={dropdownInstanceRef}
										/>
									</div>
								</div>
							</div>
						</div>
						{showError && <p style={{
							color: 'red'
						}}>Please fill all the required fields</p>}
					</fieldset>}
					<br />
					{(isSaving || isUpdating) && <LoadingSpinner size='sm' label='Saving' />}
				</div>
			</div>
		</DDSModal>
	)
}

export default CreateEditAdminModal