import React, { useEffect, useState, useContext } from "react";
import DDSModal from "../../../../Common/Modalv2/DDSModal";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { useAlert } from "react-alert";
import TelemetryMeasuresBox from "./ModalForm/InputFields/TelemetryMeasuresBox";
import SubMeasuresBox from "./ModalForm/InputFields/SubMeasureBox";
import JobFunction from "./ModalForm/InputFields/JobFunction";
import Persona from "./ModalForm/InputFields/Persona";
import JobFamily from "./ModalForm/InputFields/JobFamily";
import MeasureValues from "./ModalForm/InputFields/MeasureValue";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import "./FormStyles.css";
import { createPersonaJobFamilyMapping, updatePersonaJobFamilyMapping } from '../PersonaJobFamilyMappingAPI';
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../../react-query/constants";
import { TableContext } from "../TableContext";

const EditMeasuresModal: React.FC<{
	mode: "new" | "edit";
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	MeasuresDetails?: {
		// telemetry_measures: string;
		// sub_measure: string;
		persona: string;
		jobFunction: string;
		jobFamily: string;

		// measure_Values: string;
		id: number;
	};
}> = ({ mode, modalInstanceRef, MeasuresDetails, handleClose }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData, setMappedData } = useContext(TableContext);

	// const [MeasureInput, setMeasureInput] = useState(
	// 	MeasuresDetails?.telemetry_measures || ""
	// );
	// const [SubMeasureInput, setSubMeasureInput] = useState(
	// 	MeasuresDetails?.sub_measure || ""
	// );
	const [JobFunctionInput, setJobFunctionInput] = useState(
		MeasuresDetails?.jobFunction || ""
	);
	const [PersonaInput, setPersonaInput] = useState(
		MeasuresDetails?.persona || ""
	);
	const [JobFamilyInput, setJobFamilyInput] = useState(
		MeasuresDetails?.jobFamily || ""
	);
	// const [selectedMeasureValue, setSelectedMeasureValue] =
	// 	useState(MeasuresDetails?.measure_Values || "");

	// const refetchData = () => {
	// 	queryClient.invalidateQueries([queryKeys.TelemetryMeasuresList]);
	// };
	// Function to sanitize and extract numeric values from a string
	//   const sanitizeValue = (input: string): number => {
	// 	const numericValue = parseFloat(input.replace(/[^\d.-]/g, ''));
	// 	return isNaN(numericValue) ? 0 : numericValue;
	// };

	// Function to sanitize threshold values
	const sanitizeThreshold = (value: string | undefined) => {
		if (!value) return '';
		return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
	};
	const saveSuccessHandler = (data: any) => {

		modalInstanceRef.current.close();

		refetchData();
		alert.show("Saving...", {
			type: "success",
			timeout: 1000,
		});
		// Wait for 1 seconds before showing the alert
		setTimeout(() => {
			alert.show("Saved", {
				type: "success",
				timeout: 5000,
			});
		}, 1000);
	};

	const { mutate: saveAPI, isLoading: isSaving } = useCustomMutation(
		createPersonaJobFamilyMapping(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while saving, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const { mutate: updateAPI, isLoading: isUpdating } = useCustomMutation(
		updatePersonaJobFamilyMapping(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while updating, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const updateDetails = () => {

		// Function to sanitize threshold values
		const sanitizeThreshold = (value: string | undefined) => {
			if (!value) return '';
			return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
		};

		// Sanitize threshold values
		const sanitizedJobFamily = sanitizeThreshold(JobFamilyInput);
		const sanitizedPersona = sanitizeThreshold(PersonaInput);
		const sanitizedJobFunction = sanitizeThreshold(JobFunctionInput);

		const requestBody = {
			// telemetryMeasures: MeasureInput,
			// subMeasures: SubMeasureInput,
			// measureValues: selectedMeasureValue,
			jobFamily: JobFamilyInput,
			persona: PersonaInput,
			jobFunction: JobFunctionInput,
			id: MeasuresDetails?.id,
		};

		const saveObject = {
			...requestBody,
		};

		if (mode === "new") {
			saveAPI(saveObject);
		} else {
			updateAPI(saveObject);
		}
	};



	const CancelDetails = () => {
		modalInstanceRef.current.close();
	};

	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isSaving || isUpdating) {
			setShowError(false);
		} else {
			setShowError(!(PersonaInput && JobFamilyInput && JobFunctionInput));
		}
	}, [isSaving, isUpdating, PersonaInput, JobFamilyInput, JobFunctionInput]);

	return (
		<DDSModal
			themeColor={"white"}
			iconClass={"dds__icon--diagnostic"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__rounded dds__modal--lg"}
			// id={
			// 	mode === "new"
			// 		? "AddMeasures"
			// 		: MeasuresDetails?.telemetry_measures || ""
			// }
			id={"AddMeasures"}
			title={mode === "new" ? "Add Measures - Job Family" : "Edit Measures"}
			disposeOnClose={true}
			footerButtons={[
				{
					class: "dds__button--secondary",
					text: "Cancel",
					handleFooterButtonClick: CancelDetails,
					disabled: (isSaving || isUpdating),
				},
				{
					class: "dds__button--primary",
					text: (mode === "edit") ? "Edit measures" : "Add measures",
					handleFooterButtonClick: updateDetails,
					disabled: (showError || (isSaving || isUpdating)),
				},

			]}
			onClose={handleClose}
		>
			<>
				{!(isSaving || isUpdating) && (
					<>
						<div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "1rem", transform: "scale(0.9)" }}>
							<Persona
								mode={mode}
								PersonaInput={PersonaInput}
								setPersonaInput={setPersonaInput}
								initialize={mode === "new"}
							/>

							<JobFunction
								mode={mode}
								JobFunctionInput={JobFunctionInput}
								setJobFunctionInput={setJobFunctionInput}
								initialize={mode === "new"}
							/>

							<JobFamily
								mode={mode}
								JobFamilyInput={JobFamilyInput}
								setJobFamilyInput={setJobFamilyInput}
								initialize={mode === "new"}
							/>

						</div>

					</>
				)}
				<br />
				{(isSaving || isUpdating) && (
					<LoadingSpinner size="sm" label="Saving" />
				)}

			</>
		</DDSModal>

	);
};

export default EditMeasuresModal;