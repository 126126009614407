import { useEffect, useState } from "react";
import { useUserInfo } from "../../../../../store/auth/UserInfoContext";
import DeleteAction from "./DeleteAction";
import EditAction from "./EditAction";
import { queryKeys } from "../../../../../react-query/constants";
import { useCustomQuery } from "../../../../../hooks/useCustomQuery";
import { assetInventoryPermissionList } from "../../../API";
import { APIRequestFailedError, AuthError } from "../../../../../ErrorStates";
import { useErrorHandler } from "react-error-boundary";

const ActionColumn = ({ value, row, setData }) => {
	const userNTID = useUserInfo().getUserInfo().ntId;
	const disabled = (userNTID + "").toLowerCase() === (row.values['ntid'] + "").toLowerCase()

	/**
	 * Implementing the permission API to send the data to Action edit and delete
	 */

	const savedData = localStorage.getItem("PermissionCodes") || "{}";
	const parsedData = JSON.parse(savedData);
	const [assetTrackerValue, setAssetTrackerValue] = useState(parsedData["Asset Tracker"] || 0);
	const [error, setError] = useState<string>()
	const handleError = useErrorHandler();


	const { isLoading, error: fetchError, data: permissionData } = useCustomQuery([queryKeys.assetIventoryPermission],
		assetInventoryPermissionList(assetTrackerValue),
		{
			select: (data) => {
				return data.data;
			}
		},
	);
	useEffect(() => {
		if (fetchError) {
			console.log("error on component", fetchError)
			if (fetchError instanceof APIRequestFailedError) {
				if (fetchError.statusCode === "201")
					setError("No Record Found")
				else if (fetchError.statusCode === "204")
					setError(fetchError.message)
				else
					handleError(fetchError)
			} else if (fetchError instanceof AuthError) {
				handleError(fetchError)
			} else {
				setError(
					"Data is not loading at the moment. Please check your connection and try again."
				)
			}
		}
	}, [fetchError, handleError])

	console.log("============>permission", permissionData, row)

	if (disabled)
		return <><EditAction row={row} permissionData={permissionData} setData={setData} /></>
	return (
		<div>
			<EditAction row={row} permissionData={permissionData} setData={setData} />
			<DeleteAction row={row} moduleId={assetTrackerValue} />
		</div>

	)
}

export default ActionColumn