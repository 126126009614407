import { useState } from 'react';

const UseYNValidation = (setInput: React.Dispatch<React.SetStateAction<string>>) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const MAX_LENGTH = 3;

    // Validate input based on the provided conditions
    const validateInput = (value: string) => {
        // Allow empty input
        if (value === '') {
            setInput(value);
            setIsValid(true);
            setErrorMessage('');
            return;
        }

        // Check for spaces
        if (/\s/.test(value)) {
            setIsValid(false);
            setErrorMessage('Spaces are not allowed.');
            return;
        }

        // Check if the input exceeds the maximum length
        if (value.length > MAX_LENGTH) {
            setIsValid(false);
            setErrorMessage(`Input cannot exceed ${MAX_LENGTH} characters.`);
            return;
        }

        // Check if the input contains only alphabets
        const alphabetRegex = /^[a-zA-Z]+$/;
        if (!alphabetRegex.test(value)) {
            setIsValid(false);
            setErrorMessage('Only alphabets are allowed.');
            return;
        }

        // If input is valid till now, set the input
        setInput(value);
        setIsValid(true);
        setErrorMessage('');
    };

    // Check if the input value is 'yes' or 'no'
    const finalizeInput = (value: string) => {
        const normalizedValue = value.toLowerCase();
        if (normalizedValue === 'yes' || normalizedValue === 'no') {
            setInput(normalizedValue.charAt(0).toUpperCase() + normalizedValue.slice(1)); // Normalize to 'Yes' or 'No'
            setIsValid(true);
            // Removed the setErrorMessage for "Input must be yes or no"
        } else {
            setInput('');  // Clear input if it is not 'yes' or 'no'
            setIsValid(false);
        }
    };

    return { isValid, errorMessage, validateInput, finalizeInput, setIsValid };
};

export default UseYNValidation;
