import { useState } from 'react';

const UseIntegerValidation = (setInput: React.Dispatch<React.SetStateAction<string>>) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const MAX_LIMIT = Math.floor(Number.MAX_SAFE_INTEGER / 10);

    const validateInput = (value: string) => {
        // Regular expression to only allow positive integers (1 and above)
        const regex = /^[1-9]\d*$/;

        if (value === '') {
            setInput(value);
            setIsValid(true);
            setErrorMessage('');
        } else if (!regex.test(value)) {
            setIsValid(false);
            setErrorMessage('Input must be a positive integer (no decimals, 0 not allowed).');
        } else {
            const numericValue = parseInt(value, 10);
            if (numericValue > MAX_LIMIT) {
                setIsValid(false);
                setErrorMessage(`Largest allowed numeric value reached.`);
            } else {
                setInput(value);
                setIsValid(true);
                setErrorMessage('');
            }
        }
    };

    return { isValid, errorMessage, validateInput, setIsValid };
};

export default UseIntegerValidation;
