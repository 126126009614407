import ReactTooltip from "react-tooltip";
import DefaultCell from "../../../../Table/ReusableTableCells/DefaultCell";
import ActionColumn from "./ActionColumn/ActionColumn";
import { IconButtonWrapper } from "../../../../Dashboardv3/Card/Styles";
import { UseTableCellProps } from "react-table";
const columns: any[] = [

	{ Header: "Persona", accessor: "persona" },
	// { Header: "Device Name", acessor: "deviceName" },
	{ Header: "Job Function", accessor: "jobFunction", disableSortBy: true },
	{ Header: "Job Family", accessor: "jobFamily", disableSortBy: true, width: 20 },
	{ Header: "Actions", Cell: ActionColumn },
	{
		// Header: () => null,
		Header: "id",
		accessor: "id",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually

	},
];

export default columns;


battery
:
null
connectivity
:
null
deviceName
:
"XPS 14 9440"
deviceType
:
"Premium Notebook"
display
:
"OLED "
financialYear
:
"2025"
graphics
:
"NVIDIA GeForce RTX 4050, 6GB GDDR6"
id
:
"1"
is_exclusive
:
"Y"
memoryInGb
:
"32"
persona
:
"Builder"
processor
:
"Intel Core Ultra 7 155H"
storage
:
"512GB SSD"
thunderbolt
:
null