import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import text validation module

const GraphicsBox: React.FC<{
  mode: 'new' | 'edit';
  MeasureInput: string;
  setMeasureInput: React.Dispatch<React.SetStateAction<string>>;
  initialize?: boolean;
}> = ({ mode, MeasureInput, setMeasureInput, initialize = false }) => {

  // Use the text validation hook
  const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setMeasureInput);
  const [isFocused, setIsFocused] = useState(false);

  // Handle focus and blur events for validation feedback
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="dds__input-text__container">
      <label htmlFor="Graphics-field" className="dds__label">
        Graphics
        <span style={{ color: 'red' }}> *</span>
      </label>
      <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
        <input
          type="text"
          className="dds__input-text"
          style={{
            width: '100%',
            borderRadius: '1.5rem',
          }}
          placeholder=""
          name="Graphics-field"
          id="Graphics-field"
          required
          aria-labelledby="Graphics-label"
          onChange={(event) => validateInput(event.target.value)} // Validate input on change
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={MeasureInput}
        />
        {/* Show validation messages based on focus and validity */}
        {isFocused && !isValid && (
          <span style={{ color: 'red', fontSize: '12px' }}>
            {errorMessage}
          </span>
        )}
        {isFocused && isValid && MeasureInput && (
          <span style={{ color: 'green', fontSize: '12px' }}>
            Valid input (up to 50 characters).
          </span>
        )}
      </div>
    </div>
  );
};

export default GraphicsBox;
