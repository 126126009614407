export const PageTitle: React.FC<{ display?: string }> = ({ display = 'block', children }) => {
	return (
		<div style={{ marginBottom: '2rem' }}>
			{/* Fixed the header in each page by creating a gao betweeen header/title and content */}
			<h4
				style={{
					display: display,
				}}
				className="dds__h4 dds__font-weight-normal dds__text-white dds__mb-5"
			>
				{children}
			</h4>
		</div>
	);
} 