import React, { useState } from 'react';
import UseYNValidation from '../../../../ValidationRules/UseYNValidation'; // Import the validation component

const Thunderbolt: React.FC<{
    mode: 'new' | 'edit',
    ThunderboltInput: string,
    setThunderboltInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    ThunderboltInput,
    setThunderboltInput,
    initialize = false,
}) => {
        // Use the YN validation hook
        const { isValid, errorMessage, validateInput, finalizeInput, setIsValid } = UseYNValidation(setThunderboltInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handle focus to show validation state
        const handleFocus = () => {
            setIsFocused(true);
        };

        // Handle blur event and finalize input
        const handleBlur = () => {
            setIsFocused(false);
            finalizeInput(ThunderboltInput);  // Finalize the input and check if it is 'yes' or 'no'
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="Thunderbolt-field" className="dds__label">
                    {mode === 'edit' ? 'Thunderbolt' : 'Thunderbolt'}
                </label>
                <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: '100%',
                            borderRadius: '1.5rem',
                        }}
                        placeholder=""
                        name="Thunderbolt-field"
                        id="Thunderbolt-field"
                        required
                        aria-labelledby="Thunderbolt-label"
                        onChange={(event) => validateInput(event.target.value)}  // Use validateInput from UseYNValidation
                        onBlur={handleBlur}  // Finalize the input on blur
                        onFocus={handleFocus}
                        value={ThunderboltInput}
                    />
                    {/* Show validation messages based on focus and validity */}
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            Invalid input. {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && ThunderboltInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Enter either Yes or No
                        </span>
                    )}
                </div>
            </div>
        );
    };

export default Thunderbolt;
