import React, { useEffect, useState, useContext } from "react";
import DDSModal from "../../../../Common/Modalv2/DDSModal";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { useAlert } from "react-alert";
import TelemetryMeasuresBox from "./ModalForm/InputFields/TelemetryMeasuresBox";
import SubMeasuresBox from "./ModalForm/InputFields/SubMeasureBox";
import HighThreshold from "./ModalForm/InputFields/HighThreshold";
import LowThreshold from "./ModalForm/InputFields/LowThreshold";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import { createTelemetryMeasures, updateTelemetryMeasures } from '../UtilizationRulesAPI';
import { useQueryClient } from "@tanstack/react-query";
import { TableContext } from "../TableContext";

const EditMeasuresModal: React.FC<{
	mode: "new" | "edit";
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	MeasuresDetails?: {
		telemetry_measures: string;
		sub_measure: string;
		low_threshold: string;
		medium_threshold: string;
		high_threshold: string;
		measure_Values: string;
		id: number;
	};
}> = ({ mode, modalInstanceRef, MeasuresDetails, handleClose }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData, setMappedData } = useContext(TableContext);

	const [MeasureInput, setMeasureInput] = useState(
		MeasuresDetails?.telemetry_measures || ""
	);
	const [SubMeasureInput, setSubMeasureInput] = useState(
		MeasuresDetails?.sub_measure || ""
	);
	const [HighThresholdInput, setHighThresholdInput] = useState(
		MeasuresDetails?.high_threshold || ""
	);
	const [MediumThresholdInput, setMediumThresholdInput] = useState(
		MeasuresDetails?.medium_threshold || ""
	);
	const [LowThresholdInput, setLowThresholdInput] = useState(
		MeasuresDetails?.low_threshold || ""
	);
	const [selectedMeasureValue, setSelectedMeasureValue] =
		useState(MeasuresDetails?.measure_Values || "");

	const saveSuccessHandler = (data: any) => {

		modalInstanceRef.current.close();

		refetchData();
		alert.show("Saving...", {
			type: "success",
			timeout: 1000,
		});
		// Wait for 5 seconds before showing the alert
		setTimeout(() => {
			alert.show("Saved", {
				type: "success",
				timeout: 5000,
			});
		}, 1000);

	};

	const { mutate: saveAPI, isLoading: isSaving } = useCustomMutation(
		createTelemetryMeasures(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while saving, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const { mutate: updateAPI, isLoading: isUpdating } = useCustomMutation(
		updateTelemetryMeasures(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while updating, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const updateDetails = () => {

		const requestBody = {
			telemetryMeasures: MeasureInput,
			subMeasure: SubMeasureInput,
			// measureValues: "%",
			lowThreshold: LowThresholdInput,
			mediumThreshold: LowThresholdInput + " to " + HighThresholdInput,
			highThreshold: HighThresholdInput,
			id: MeasuresDetails?.id,
		};

		const saveObject = {
			...requestBody,
		};

		if (mode === "new") {
			saveAPI(saveObject);
		} else {
			updateAPI(saveObject);
		}


	};



	const CancelDetails = () => {
		modalInstanceRef.current.close();
	};

	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isSaving || isUpdating) {
			setShowError(false);
		} else {
			setShowError(!(MeasureInput && SubMeasureInput && LowThresholdInput && HighThresholdInput));
		}
	}, [isSaving, isUpdating, MeasureInput, SubMeasureInput, LowThresholdInput, HighThresholdInput]);

	return (


		<DDSModal
			themeColor={"white"}
			iconClass={"dds__icon--diagnostic"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__modal--lg"}
			id={
				mode === "new"
					? "AddMeasures"
					: MeasuresDetails?.telemetry_measures || ""
			}
			title={mode === "new" ? "Add Measures - Utilization Rules" : "Edit Measures"}
			disposeOnClose={true}
			footerButtons={[
				{
					class: "dds__button--secondary",
					text: "Cancel",
					handleFooterButtonClick: CancelDetails,
					disabled: (isSaving || isUpdating),
				},
				{
					class: "dds__button--primary",
					text: (mode === "edit") ? "Edit measures" : "Add measures",
					handleFooterButtonClick: updateDetails,
					disabled: (showError || (isSaving || isUpdating)),
				},
			]}
			onClose={handleClose}
		>
			<>
				{!(isSaving || isUpdating) && (
					<>
						<div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "1rem", transform: "scale(0.9)" }}>
							<TelemetryMeasuresBox
								mode={mode}
								MeasureInput={MeasureInput}
								setMeasureInput={setMeasureInput}
								initialize={mode === "new"}
							/>



							<SubMeasuresBox
								mode={mode}
								SubMeasureInput={SubMeasureInput}
								setSubMeasureInput={setSubMeasureInput}
								initialize={mode === "new"}
							/>



							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<LowThreshold
										mode={mode}
										LowThresholdInput={LowThresholdInput}
										setLowThresholdInput={setLowThresholdInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<HighThreshold
										mode={mode}
										HighThresholdInput={HighThresholdInput}
										setHighThresholdInput={setHighThresholdInput}
										initialize={mode === "new"}
									/>
								</div>
							</div>

						</div>
					</>
				)}
				<br />
				{(isSaving || isUpdating) && (
					<LoadingSpinner size="sm" label="Saving" />
				)}

			</>
		</DDSModal>

	);
};

export default EditMeasuresModal;
