import React, { useEffect, useState, useContext } from "react";
import DDSModal from "../../../../Common/Modalv2/DDSModal";
import { useCustomMutation } from "../../../../../hooks/useCustomQuery";
import { useAlert } from "react-alert";
import IsUsedBox from "./ModalForm/InputFields/IsUsed";
import FieldNamesBox from "./ModalForm/InputFields/FieldName";
import FeatureDescription from "./ModalForm/InputFields/FeatureDescription";
import FeatureWeightage from "./ModalForm/InputFields/FeatureWeightage";
import FeatureNameBox from "./ModalForm/InputFields/FeatureName";
import MeasureValues from "./ModalForm/InputFields/MeasureValue";
import LoadingSpinner from "../../../../Common/LoadingSpinner";
import "./FormStyles.css";
import { createFeaturesWithWeightage, updateFeaturesWithWeightage } from '../../../IPSSMeasures/FeaturesWithWeightage/FeaturesWithWeightageAPI';
import { useQueryClient } from "@tanstack/react-query";
import { TableContext } from "../TableContext";
import IsUsed from "./ModalForm/InputFields/IsUsed";

const EditMeasuresModal: React.FC<{
	mode: "new" | "edit";
	handleClose: any;
	modalInstanceRef: React.MutableRefObject<any>;
	MeasuresDetails?: {
		fieldName: string;
		featureName: string;
		featureWeightage: string;
		isUsed: string,
		featureDescription: string;

		id: number;
	};
}> = ({ mode, modalInstanceRef, MeasuresDetails, handleClose }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData, setMappedData } = useContext(TableContext);

	const [FieldNameInput, setFieldNameInput] = useState(
		MeasuresDetails?.fieldName || ""
	);
	const [FeatureDescriptionInput, setFeatureDescriptionInput] = useState(
		MeasuresDetails?.featureDescription || ""
	);
	const [FeatureWeightageInput, setFeatureWeightageInput] = useState(
		MeasuresDetails?.featureWeightage || ""
	);
	const [FeatureNameInput, setFeatureNameInput] = useState(
		MeasuresDetails?.featureName || ""
	);


	const [isUsedValue, setisUsedValue] =
		useState(MeasuresDetails?.isUsed || "");

	
	// Function to sanitize threshold values
	const sanitizeThreshold = (value: string | undefined) => {
		if (!value) return '';
		return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
	};
	const saveSuccessHandler = (data: any) => {

		modalInstanceRef.current.close();

		refetchData();
		alert.show("Saving...", {
			type: "success",
			timeout: 1000,
		});
		// Wait for 1 seconds before showing the alert
		setTimeout(() => {
			alert.show("Saved", {
				type: "success",
				timeout: 5000,
			});
		}, 1000);
	};

	const { mutate: saveAPI, isLoading: isSaving } = useCustomMutation(
		createFeaturesWithWeightage(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while saving, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const { mutate: updateAPI, isLoading: isUpdating } = useCustomMutation(
		updateFeaturesWithWeightage(),
		{
			onSuccess: saveSuccessHandler,
			onError: error => {
				console.error(error);
				alert.show("Error Occurred while updating, please try again", {
					type: "error",
					timeout: 5000,
				});
			},
		}
	);

	const updateDetails = () => {

		// Function to sanitize threshold values
		const sanitizeThreshold = (value: string | undefined) => {
			if (!value) return '';
			return value.replace(/[^\d.]/g, ''); // Replace non-numeric characters except dot
		};

		// Sanitize threshold values
		// const sanitizedFeatureName = sanitizeThreshold(FeatureNameInput);
		const sanitizedFeatureWeightage = sanitizeThreshold(FeatureWeightageInput);
		const sanitizedFeatureDescription = sanitizeThreshold(FeatureDescriptionInput);

		const requestBody = {
			isUsed: isUsedValue,
			fieldName: FieldNameInput,
			// measureValues: selectedMeasureValue,
			featureName: FeatureNameInput,
			featureWeightage: FeatureWeightageInput,
			featureDescription: FeatureDescriptionInput,
			id: MeasuresDetails?.id,
		};

		const saveObject = {
			...requestBody,
		};

		if (mode === "new") {
			saveAPI(saveObject);
		} else {
			updateAPI(saveObject);
		}
	};



	const CancelDetails = () => {
		modalInstanceRef.current.close();
	};

	const [showError, setShowError] = useState<boolean>(false);

	useEffect(() => {
		if (isSaving || isUpdating) {
			setShowError(false);
		} else {
			setShowError(!(FieldNameInput && FeatureWeightageInput && isUsedValue && FeatureNameInput));
		}
	}, [isSaving, isUpdating, FieldNameInput, FeatureWeightageInput, isUsedValue, FeatureNameInput]);

	return (
		<DDSModal
			themeColor={"white"}
			iconClass={"dds__icon--diagnostic"}
			modalInstanceRef={modalInstanceRef}
			classes={"dds__rounded dds__modal--lg"}
			// id={
			// 	mode === "new"
			// 		? "AddMeasures"
			// 		: MeasuresDetails?.telemetry_measures || ""
			// }
			id={"AddMeasures"}
			title={mode === "new" ? "Add Measures - Features With Weightage" : "Edit Measures"}
			disposeOnClose={true}
			footerButtons={[
				{
					class: "dds__button--secondary",
					text: "Cancel",
					handleFooterButtonClick: CancelDetails,
					disabled: (isSaving || isUpdating),
				},
				{
					class: "dds__button--primary",
					text: (mode === "edit") ? "Edit measures" : "Add measures",
					handleFooterButtonClick: updateDetails,
					disabled: (showError || (isSaving || isUpdating)),
				},
			]}
			onClose={handleClose}
		>

			<>
				{!(isSaving || isUpdating) && (
					<>
						<div style={{ display: "flex", width: "100%", flexDirection: "column", gap: "1rem", transform: "scale(0.9)" }}>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<FieldNamesBox
										mode={mode}
										FieldNameInput={FieldNameInput}
										setFieldNameInput={setFieldNameInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<FeatureNameBox
										mode={mode}
										FeatureNameInput={FeatureNameInput}
										setFeatureNameInput={setFeatureNameInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<FeatureWeightage
										mode={mode}
										FeatureWeightageInput={FeatureWeightageInput}
										setFeatureWeightageInput={setFeatureWeightageInput}
										initialize={mode === "new"}
									/>
								</div>
								<div style={{ flex: "1" }}>
									<IsUsedBox
										mode={mode}
										MeasureInput={isUsedValue}
										setMeasureInput={setisUsedValue}
										initialize={mode === "new"}
									/>
								</div>
							</div>
							<div style={{ display: "flex", width: "100%", flexDirection: "row", gap: "1rem" }}>
								<div style={{ flex: "1" }}>
									<FeatureDescription
										mode={mode}
										FeatureDescriptionInput={FeatureDescriptionInput}
										setFeatureDescriptionInput={setFeatureDescriptionInput}
										initialize={mode === "new"}
									/>
								</div>

							</div>
						</div>

					</>
				)}
				<br />
				{(isSaving || isUpdating) && (
					<LoadingSpinner size="sm" label="Saving" />
				)}

			</>
		</DDSModal>
	);
};

export default EditMeasuresModal;
