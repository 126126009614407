import useMyAssetsData from '../../../AssetInventory/MyAssets/useMyAssetsData'
import LoadingSpinner from '../../../Common/LoadingSpinner';
import { useDashboardContext } from '../../../../store/dashboard/DashboardContext';
import { inventory } from '../../constants';
import DDSAccordian from '../../Accordian/DDSAccordian';
import NoRecordFound from '../../CardTable/NoRecordFound';
import { createAccordianID } from '../Asset Request Tracker Card/User';
import CardError from '../CardError';

const columns = [{
	Header: "Asset Number",
	accessor: "asset_number",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,
}, {
	Header: "Product Name",
	accessor: "product_name",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,
}, {
	Header: "Status",
	accessor: "status",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,
},
{
	Header: "SHIR Enabled",
	accessor: "shirCapable",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: false,
},
{
	Header: "Asset Location",
	accessor: "asset_location",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: true,
}, {
	Header: "Cost Center",
	accessor: "cost_center",
	Cell: ({ value }) => value ? <>{value}</> : <>"NA"</>,
	searchable: true,
	visible: true,
},
{
	Header: "Funded By",
	accessor: "fundedby",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: true,
},
{
	Header: "Condition",
	accessor: "condition",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: true,
},
{
	Header: "Self-Healing Image Recovery (SHIR)",
	accessor: "shirCapable",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: true,
},
{
	Header: "SHIR Reason",
	accessor: "shirReason",
	Cell: ({ value }) => <>{value}</>,
	searchable: true,
	visible: true,
},
]

const User: React.FC = () => {
	const { isOrg, activeTab } = useDashboardContext();
	const { isLoading, data, isError } = useMyAssetsData(
		(!isOrg || (activeTab.category === 'all' && activeTab.name === inventory)));

	if (isLoading) return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (isError) return <CardError />;

	if (data.length === 0)
		return <NoRecordFound />

	return (
		<DDSAccordian
			totalElements={data.length}
			data={data}
			isError={isError}
			isLoading={isLoading}
			columns={columns}
			accordianID={createAccordianID(inventory)}
		/>
	);
}

export default User