import { objectToQuery } from "../../../../utils/querycreator";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const createDeviceTypeRanking = (): object => ({
	url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/deviceTypeRanking`,
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});
export const updateDeviceTypeRanking = (): object => ({
	url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/deviceTypeRanking`,
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getDeviceTypeConfig = (dellID: String) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/deviceTypeRanking`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};

export const DeleteDeviceTypeQueryConfig = (id: number) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/deviceTypeRanking?id=${id}`,
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}