export const queryKeys = {
	token: "token",
	userinfo: "user-info",
	myassets: "inventory-myassets",
	overallassetdetails: "overall-asset-details",
	leaderproductdetails: "leader-product-details",
	orgcount: "inventory-orgcount",
	inventoryadmin: "inventory-admincard",
	orgtable: "inventory-orgtable",
	inventoryadminlist: "inventory-adminlist",
	inventoryregions: "inventory-regionlist",
	eoluser: "eol-user",
	eoladmin: "eol-admin",
	userImage: "user-image",
	mobileDeviceFilter: "mobileDevice-filterList",
	blockeddevice: "blocked-device",
	blockedDeviceAdmin: "blocked-device-admin",
	assetInventorysidemenu: "asset-inventory-sidemenu",
	assetIventoryPermission: "asset-inventory-permission",

	cvlchunk: "cvl-chunk",
	cvluser: "cvl-user-data",
	cvladmin: "cvl-all-data",
	cvlrefresh: "cvl-refresh-date",
	cvlsupport: "cvl-support-link",

	cvladminlist: "cvl-admin-list",

	metaData: "meta",
	dellyticsReg: "dellytics-registration",

	ARTUserCard: "ART-Card",
	ATCard: "AT-Card",
	EOLCard: "EOL-Card",
	VDICard: "VDI-Card",
	UACard: "UA-Card",
	PasswordManagementCard: "PM-Card",

	ATStatusList: "AT-status-list",
	ATProductiveList: "AT-productive-list",

	ARTAdminList: "ART-admin-list",
	announcements: "announcements",
	downtimeMessage: "downtimeMessage",

	MDStats: "MD-stats",
	MDTable: "MD-table",
	PCVersionCard: "PC-Version-Card",

	BDDeviceSearch: "BD-Device-Search",

	commandStatusList: "command-status-list",
	TelemetryMeasuresList: "Telemetry-Measures-List",
	measureValues: "measure-Values",
	TelemetryMeasuresTable: "Telemetry-Measures-Table",
	BannerIntune: "Banner-Intune",
	FeaturesWithWeightageList: "FeaturesWithWeightageList",
	FilterList: "Filter-List",
};
