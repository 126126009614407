import { createContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { APIRequestFailedError, AuthError } from "../../../../ErrorStates";
import { useCustomQuery } from "../../../../hooks/useCustomQuery";
import LoadingSpinner from "../../../Common/LoadingSpinner";
import { useQueryClient } from "@tanstack/react-query";

export const TableContext = createContext<any>(null);

// Define TableContextProvider component
export const TableContextProvider = ({ apiConfig, children }) => {
    const queryClient = useQueryClient();

    const { isLoading, error: fetchError, data: telemetryData, refetch } = useCustomQuery(
        [apiConfig.keyName],
        apiConfig.getMeasuresConfig(), // Assuming this fetches telemetry measures
        {
            select: (data) => {
                return data.data; // Assuming telemetry measures are in data.data
            }
        }
    );

    const [error, setError] = useState<string>();
    const handleError = useErrorHandler(); // Assuming this is a custom hook to handle errors
    const [mappedData, setMappedData] = useState<any[]>([]);
    // Remove filteredData state and filterData function as they are no longer needed

    useEffect(() => {
        refetch()
        if (telemetryData != null) {
            // Sort telemetryData by telemetryMeasures in a case-insensitive manner
            const sortedData = [...telemetryData].sort((a, b) => {
                // Convert telemetryMeasures to lowercase for case-insensitive comparison
                console.log("telemetrydata", telemetryData);
                const measureA = (a.telemetryMeasures || "").toLowerCase();
                const measureB = (b.telemetryMeasures || "").toLowerCase();
                // const measureA = a.telemetryMeasures.toLowerCase();
                // const measureB = b.telemetryMeasures.toLowerCase();

                // Compare telemetryMeasures case-insensitively
                if (measureA < measureB) return -1;
                if (measureA > measureB) return 1;
                return 0;
            });

            // Map sortedData to match the expected structure
            const mappedResults = sortedData.map((item) => {
                const mappedItem: any = {
                    telemetry_measures: item.telemetryMeasures,
                    sub_measure: item.subMeasure,
                    // measure_Values: item.measureValues,
                    id: item.id,
                };

                if (item.lowThreshold !== null) {
                    // mappedItem.low_threshold = `${item.lowThreshold.slice(1, -1)}`;
                    let lowThreshold = item.lowThreshold;
                    mappedItem.low_threshold = lowThreshold;

                }



                if (item.highThreshold !== null) {
                    // mappedItem.high_threshold = `${item.highThreshold.slice(1, -1)}`;
                    let highThreshold = item.highThreshold;
                    mappedItem.high_threshold = highThreshold;
                }
                if (item.lowThreshold && item.highThreshold) {

                    mappedItem.medium_threshold = mappedItem.low_threshold + " to " + mappedItem.high_threshold;

                }
                return mappedItem;
            });

            setMappedData(mappedResults);
            console.log("Telemetry Data loaded:", telemetryData);
        }
    }, [telemetryData]);

    useEffect(() => {
        if (fetchError) {
            console.log("Error on component", fetchError);
            if (fetchError instanceof APIRequestFailedError) {
                if (fetchError.statusCode === "201") {
                    setError("No Record Found");
                } else if (fetchError.statusCode === "204") {
                    setError(fetchError.message);
                } else {
                    handleError(fetchError);
                }
            } else if (fetchError instanceof AuthError) {
                handleError(fetchError);
            } else {
                setError(
                    "Data is not loading at the moment. Please check your connection and try again."
                );
            }
        }
    }, [fetchError, handleError]);

    const handleRefetch = () => {
        refetch(); // Trigger manual refetch of data
    };

    const deleteSuccessHandler = (data) => {
        queryClient.invalidateQueries([apiConfig.keyName]); // Invalidate and refetch data
    };

    // Render children when data is loaded and no error
    return <TableContext.Provider value={{ allData: mappedData, refetchData: handleRefetch, setMappedData }}>
        {(isLoading || error) && <div className="dds__mx-auto dds__text-center" style={{
            marginTop: "calc(100px + 1.5rem)"
        }}>
            {isLoading &&
                <LoadingSpinner label="Fetching Utilization Rules" />
            }
            {error && <>{error}</>}

        </div>}
        {!isLoading && !error && children}
    </TableContext.Provider>
};
