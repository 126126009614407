import React, { useEffect, useRef, useState } from 'react'
import useAxios from '../../../../../../hooks/useAxios'
import LoadingSpinner from '../../../../../Common/LoadingSpinner'
import { orgAutofillUserConfig } from '../../../../API'
import { extraEmployeeDetails } from '../useDropdown'
import { Dropdown } from '@dds/components'
import { v4 } from 'uuid'
import { useUserInfo } from '../../../../../../store/auth/UserInfoContext'

//TODO: Error state updates are not instant so if the user types fast, error state might not get correctly updated, same with loading as well
const SearchUserBox: React.FC<{
	mode: 'new' | 'edit',
	searchInput: string,
	setSearchInput: React.Dispatch<React.SetStateAction<string>>
	disableSearch: boolean,
	setDisableSearch: React.Dispatch<React.SetStateAction<boolean>>
	setExtraEmployeeInformation: React.Dispatch<React.SetStateAction<extraEmployeeDetails>>
	initialize?: boolean
}> = ({
	mode,
	searchInput,
	setSearchInput,
	disableSearch,
	setDisableSearch,
	setExtraEmployeeInformation,
	initialize = false,
}) => {
		let dropdownRef = useRef<any>();
		let dropdownInstanceRef = useRef<any>();

		const currentUser = useUserInfo();
		// useEffect(() => {
		// 	// Initialize Dropdown role-dropdown
		// 	if (initialize) {
		// 		console.log("Search dropdown init", dropdownRef.current)
		// 		dropdownInstanceRef = new Dropdown(dropdownRef.current);
		// 	}
		// 	return () => {
		// 		if (dropdownInstanceRef.current)
		// 			dropdownInstanceRef.current.dispose();
		// 	}
		// }, [])

		// To autofill user info
		const { isLoading: fetchingUsers, error: fetchUserError, sendRequest: fetchUsers } = useAxios();

		// userList for autofill
		type user = { email: string, name: string, ntid: string, selected: boolean, badgeId: string }
		const [userList, setUserList] = useState<user[]>([])

		// search input change handler
		const onEmailInputChange = (event) => {
			event.preventDefault();
			setSearchInput(event.target.value)
			// setUserList(old =>
			// 	old.map(e => {
			// 		if (e.email !== event.target.value)
			// 			return {
			// 				...e,
			// 				selected: false
			// 			}
			// 		return {
			// 			...e,
			// 			selected: true
			// 		}
			// 	}))
		}

		// fetch users whenever search input changes
		useEffect(() => {
			let users: user[] = []
			if (searchInput !== "" && mode === 'new') {
				let flag = 0
				userList.forEach(element => {
					console.log(element)
					if (element.selected === true) {
						flag = 1;
					}
				});
				if (flag === 0)
					fetchUsers(orgAutofillUserConfig(searchInput), data => {

						data.content.forEach(user => {
							if (user.email.toLowerCase() !== currentUser.getUserInfo().email.toLowerCase())
								users.push({ selected: false, email: user.email, name: user.userName, ntid: user.ntId, badgeId: user.badgeId })
						})
						setUserList(users)
						console.log(searchInput)
						console.log(users)
					})
			}
			else {
				setUserList([])
			}
		}, [searchInput])

		// set search input to the selected user and disable input
		useEffect(() => {
			userList.forEach(element => {
				console.log(element)
				if (element.selected === true) {
					setSearchInput(element.email)
					setDisableSearch(true)
				}
			});
		}, [userList])
		// Select user from autofill list
		const selectUserHandler = (email: string) => {
			console.log("e", email)
			setUserList(old =>
				old.map(e => {
					if (e.email === email) {
						setExtraEmployeeInformation({
							ntid: e.ntid,
							admname: e.name,
							accesstype: 'default',
							badgeId: e.badgeId
						})
						return {
							...e,
							selected: true
						}
					}
					return e
				}))
		}
		return (
			<div ref={dropdownRef} className="dds__dropdown" data-dds="" id={"user-dropdown" + v4()} data-selection="single">
				<div className="dds__dropdown__input-container">
					<label id="email-label" htmlFor="email-field">{mode === 'edit' ? 'Email' : 'Search by name *'}</label>
					<div className="dds__dropdown__input-wrapper">
						<input
							type="text"
							className="dds__dropdown__input-field"
							placeholder="Choose Employee Email"
							// minLength={2}
							maxLength={100}
							name="email-field"
							id="email-field"
							required
							aria-labelledby="email-field-label email-field-helper"
							onChange={onEmailInputChange}
							value={searchInput}
							disabled={disableSearch}
						/>
						<div id="email-feedback" className={`${userList.length === 0 && searchInput !== '' && !disableSearch && !fetchingUsers && fetchUserError?.message !== 'canceled' ? 'dds__d-block' : 'dds__d-none'}`} style={{
							opacity: 0.8,
							fontSize: '0.75rem',
							fontWeight: 400,
							letterSpacing: '0.005em',
							lineHeight: ' 1.25rem',
							color: '#bb2a33',
							marginBottom: '1rem',
						}}>No user found, please enter correct name.</div>
					</div>
					{<div id={`user-dropdown-popup`} className={`dds__dropdown__popup ${(userList.length > 0 && !disableSearch) || fetchingUsers ? 'dds__dropdown__popup--visible' : 'dds__dropdown__popup--hidden'}`} role="presentation" tabIndex={-1}
						style={{
							maxHeight: '15rem',
							position: 'initial'
						}}
					>
						<ul className="dds__dropdown__list" role="listbox" tabIndex={-1}>
							{userList.length === 0 && <li className="dds__dropdown__item" role="none" key={"none"}>
								<button disabled className="dds__dropdown__item-option" role="option" data-selected="false" data-value={''} tabIndex={-1}>
									<span className="dds__dropdown__item-label">{''}</span>
								</button>
							</li>}
							{fetchingUsers &&

								<li className="dds__dropdown__item" role="none" key={"loading"}>
									<button disabled className="dds__dropdown__item-option" role="option" data-selected="false" data-value={"loading"} tabIndex={-1}>
										<span className="dds__dropdown__item-label"><LoadingSpinner size='sm' /></span>
									</button>
								</li>
							}
							{!fetchingUsers &&
								userList?.map((e, index) => {
									return <li className="dds__dropdown__item" role="none" key={e.email}>
										<button onClick={() => selectUserHandler(e.email)} className="dds__dropdown__item-option" role="option" data-selected="false" data-value={e.email} tabIndex={-1}>
											<span className="dds__dropdown__item-label">{e.email}</span>
										</button>
									</li>
								})
							}
						</ul>
					</div>}
				</div>
			</div>
		)
	}
export default SearchUserBox