import LoadingSpinner from "../../Common/LoadingSpinner";
import { useEffect, useState } from "react";
import DashboardToolbar from "../DashboardToolbar/DashboardToolbar";
import DummyFavoriteCard from "./DummyFavoriteCard";
import { breakpointsInfo } from "../constants";
import ResponsiveLayout from "./ResponsiveLayout";
import { AssistiveText, ResponsiveWrapper } from "./Styles";
import { useAnalytics } from "../../../store/analytics/AnalyticsContext";
import { eventsInfo } from "../../../store/analytics/eventsInfo";
import { useDashboardAPIs } from "./useDashboardAPIs";
import useResponsiveDragLayout from "./useResponsiveDragLayout";
import { useDashboardContext } from "../../../store/dashboard/DashboardContext";
import Banner from "../DashboardBanner/Banner";
import DummyActionCard from "./DummyActionCard";
import sanitizeHtml from 'sanitize-html';
import { getMyObject, setMyObject } from "../Drawer/PermissionStorageUtils";

const Dashboardv3: React.FC = () => {
	const { isMetaDataLoading, dataError, data, downtimeMessage } = useDashboardAPIs();
	const [myObject, setMyObjectState] = useState<{ [key: string]: string }>({});
	const { addEventToQueue } = useAnalytics();
	useEffect(() => {
		addEventToQueue(eventsInfo.dashboardLoad()); // Dashboard load event
	}, []);


	const {
		handleActiveChange,
		layoutChangeHandler,
		layout } = useResponsiveDragLayout();
	const { active } = useDashboardContext();

	let breakpoints = {};
	Object.entries(breakpointsInfo).forEach(([breakpoint, details]) => {
		breakpoints[breakpoint] = details.min;
	});


	const getActiveOrder = () => {
		if (data) {
			switch (active) {
				case 'all': return data.order;
				case 'fav': return data.orderFavouritedCards;
				case 'org': return data.orderOrgCards;
				case 'action': return data.orderUserActions;
			}
		}
		return [];

	}
	const [order, setOrder] = useState<string[]>(getActiveOrder());

	useEffect(() => {
		console.log('Active changed', active)
		if (!isMetaDataLoading)
			setOrder(getActiveOrder());
	}, [active, isMetaDataLoading]);

	/**
	 * Initialize the state from sessionStorage
	 * Storing the permissionCodes in the local storage based on that moduleId is shared across the application based on the tab
	 * 
	 */
	useEffect(() => {
		if (data) {
			// Initialize the state from sessionStorage
			const currentObject = getMyObject();
			setMyObjectState(currentObject);
			let newObject: { [key: string]: string } = {};

			// Define the new object based on tabs
			for (const tab in data.tabs) {
				if (data.tabs[tab].moduleID) {
					newObject[tab] = data.tabs[tab].moduleID;
				}
			}

			// Merge newObject with the existing state
			const updatedObject = { ...currentObject, ...newObject };

			// Update localStorage with the merged state
			setMyObject(updatedObject);

			// Optionally update the state in the component if needed
			setMyObjectState(updatedObject);
			// Merge newObject with the existing state
		}
	}, [data])

	// log if order changed
	useEffect(() => {
		console.log('Active changed order', order)
	}, [order]);

	useEffect(() => {
		if (active === 'org') {
			document.title = "TrackIT | My Organization"
		} else if (active === 'all')
			document.title = "TrackIT | Digital Experience Dashboard"
		else if (active === 'fav')
			document.title = "TrackIT | My Favourites"
		else if (active === 'action')
			document.title = "TrackIT | My Actions"
	}, [active])

	const DummyCardWrapper = ({ children }: { children: React.ReactNode }) => {
		return (
			<div className="dds__d-flex dds__justify-content-around dds__mt-4">
				{children}
			</div>
		)
	}

	if (isMetaDataLoading || !active)
		return <LoadingSpinner size="lg"></LoadingSpinner>;
	if (dataError) return <div>Error</div>;

	return (
		<>
			{downtimeMessage && <div
				role="dialog"
				aria-label="Application Downtime Information"
				style={{
					// height: "100%",
					backgroundColor: "#FFECEE",
					color: "#590A0F",
					gap: "1rem"
				}} className="dds__d-flex dds__justify-content-center dds__align-items-center dds__p-2">
				<i
					className="dds__icon dds__icon--windows-support"
				/>
				<p
					dangerouslySetInnerHTML={{ __html: sanitizeHtml(downtimeMessage) }} style={{ margin: 0 }}></p>
			</div>}
			{/* Component to show greetings to the user in real time */}
			<Banner />
			<ResponsiveWrapper className="dds__container dds__container--compact dds__my-4">
				<DashboardToolbar
					handleActiveChange={handleActiveChange}
					totalApps={data.total}
					totalFavourite={data.totalFavourite}
					totalUserActions={data.totalUserActions}
					totalOrgCards={data.totalOrgCards}
				/>
				{active === "fav" && data.totalFavourite === 0 && (
					<DummyCardWrapper>
						<DummyFavoriteCard />
					</DummyCardWrapper>
				)}
				{active === "action" && data.totalUserActions === 0 && (
					<DummyCardWrapper>
						<DummyActionCard />
					</DummyCardWrapper>
				)}
				{order.length > 0 && <ResponsiveLayout
					layout={layout}
					breakpoints={breakpoints}
					layoutChangeHandler={layoutChangeHandler}
					data={data}
					order={order}
				/>}

				<AssistiveText id="operation" >Drag and drop with mouse to reorder</AssistiveText>
			</ResponsiveWrapper>
		</>
	);
};

export default Dashboardv3;
