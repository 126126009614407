import styled from 'styled-components';

export const ContentBox = styled.div`
  background-color: white;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
  padding-bottom: 4rem;
`;

export const ContentBoxBulk = styled.div`
  background-color: white;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  margin-left: 2rem;
  padding-bottom: 2rem;
`;

export const ProgressBox = styled.div`
  width: 100%;
  height: 7.5rem;
  padding: 2rem;
  padding-top: 0.4rem;
  background-color: rgb(217, 245, 253);
`;
