import ClientTable from '../../../Common/ClientTable/ClientTable';
import DefaultCell from '../../../Table/ReusableTableCells/DefaultCell';
import ActionColumn from "./DataModel/ActionColumn/ActionColumn";
const ExpandComponent = ({ row, columns, expandProps }) => {

    console.log("rowobject", row)
    console.log(columns)
    const SubData = [
        {
            actioncol: row.values["actioncol"],
            configs_name: row.values["configs_name"],
            // low_threshold: row.values["low_threshold"],
            // medium_threshold: row.values["medium_threshold"],
            // high_threshold: row.values["high_threshold"],
            actions: row.values["actions"],
        }
    ];
    const SubColumns = [
        {
            Header: () => null, // No header
            id: '__id',
            Cell: DefaultCell,
            disableResizing: true,
            width: 50,
        },

        { Header: 'Config Name', accessor: 'configs_name' },
        { Header: 'Action', accessor: 'actioncol' },
        // { Header: 'Low Threshold', accessor: 'low_threshold' },
        // { Header: 'Medium Threshold', accessor: 'medium_threshold' },
        // { Header: 'High Threshold', accessor: 'high_threshold' },
        {
            Header: 'Actions', accessor: 'actions', width: 70,
            Cell: ActionColumn,
        },

        // More SubColumns...
    ];


    return (
        <ClientTable
            data={SubData}
            columns={SubColumns}
            skipStateReset={false}
            disableToolbar={true}
            disableHeaderRow
        />

    )
}
export default ExpandComponent