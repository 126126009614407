import { objectToQuery } from "../../../../utils/querycreator";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const createPersonaJobFamilyMapping = (): object => ({
	url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/personaJobFamilyMapping", //using pcf url for now for CRUD
	method: "POST",
	headers: {
		"Content-Type": "application/json",
	},
});

export const updatePersonaJobFamilyMapping = (): object => ({
	url: "https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/personaJobFamilyMapping", //using pcf url for now for CRUD
	method: "PUT",
	headers: {
		"Content-Type": "application/json",
	},
});

export const getPersonaJobFamilyMapping = (dellID: string) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/personaJobFamilyMapping`, //using pcf url for now for CRUD
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const getSearchMeasuresConfig = (q: string) => {
	return {
		url: baseUrl + `/api/Fetch/v1/TelemertyMeasuresSearchValues?q=${q}`,
		method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
	};
};
export const measureValuesQueryConfig = () => ({
	url: baseUrl + "/api/Fetch/v1/TelemertyMeasuresValues",
	method: "GET",
	headers: {
		"Content-Type": "application/json",
	},
});


export const DeletePersonaJobFamilyMapping = (id: number) => {
	return {
		url: `https://trackit-restapi.anp2.pcf.dell.com/ipss/v1/personaJobFamilyMapping?id=${id}`, //using pcf url for now for CRUD
		method: "DELETE",
		headers: {
			"Content-Type": "application/json",
		},
	}
}