import ActionColumn from "./ActionColumn/ActionColumn";

const columns: any[] = [

	{ Header: "Config Name", accessor: "configName", disableSortBy: true },
	{ Header: "Action", accessor: "action", disableSortBy: true },
	{ Header: "Actions", Cell: ActionColumn },
	{
		// Header: () => null,
		Header: "id",
		accessor: "id",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually
	}
];

export default columns;
