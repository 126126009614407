import React, { useState, useEffect } from 'react';
import Default_dp from "./Default_dp.svg";

interface ImageFetcherProps {
  searchItem: string;
}

const ImageFetcher: React.FC<ImageFetcherProps> = ({ searchItem }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`https://orglyapiservices.anp2.pcf.dell.com/api/v3/picture1?search_item=${searchItem}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageUrl(url);
      } catch (error) {
        console.error('Error fetching the image:', error);
        setError("Error");
      }
    };

    fetchImage();
  }, [searchItem]);

  return (
    <div>
      {error || !imageUrl ? (
        <img
          src={Default_dp}
          alt="Default"
          style={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            objectFit: 'cover',
            marginLeft: '5px',
            border: '1px solid black',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow
          }}
        />
      ) : (
        <img
          src={imageUrl}
          alt={searchItem}
          style={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            objectFit: 'cover',
            marginLeft: '5px',
            border: '1px solid black',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow
          }}
        />
      )}
    </div>
  );
};

export default ImageFetcher;