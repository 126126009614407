import React, { useRef, useContext } from 'react';
import DDSModal from "../../../../../Common/Modalv2/DDSModal";
import { IconButton } from "../../../../../Table/TableStyles";
import ReactTooltip from 'react-tooltip'; // Replace with actual tooltip library
import { useCustomMutation } from "../../../../../../hooks/useCustomQuery";
import { queryKeys } from "../../../../../../react-query/constants";
import LoadingSpinner from "../../../../../Common/LoadingSpinner";
import { useAlert } from "react-alert";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteCatalog } from '../../CatalogAPI';
import { TableContext } from "../../TableContext";
const DeleteAction = ({ row }) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const { refetchData, setMappedData } = useContext(TableContext);
	let modalInstanceRef = useRef<any>();
	const deleteSuccessHandler = data => {
		alert.show(
			`Successfully removed device ${row.values.deviceName} from the Catalog Measures group`,
			{
				type: "success",
				timeout: 5000,
			}
		);
		setMappedData((prevData) =>
			prevData.filter((item) => item.id !== row.values.id)
		);

		refetchData();
	};

	const deleteErrorHandler = () => {
		alert.show("Unknown error occurred while deleting the record", {
			type: "error",
			timeout: 10000,
		});
	};
	//   let TelemetryData = {
	//     telemetryMeasures: row.values.telemetry_measures,
	//     subMeasures: row.values.sub_measure,
	//     measureValues:"",
	//     lowThreshold: row.values.low_threshold,
	//     mediumThreshold: "",
	//     highThreshold:row.values.high_threshold,
	// 	id:row.values.id
	// };

	const { isLoading: isDeleting, mutate: deleteRecord } = useCustomMutation(DeleteCatalog((row.values.id)), {
		onSuccess: deleteSuccessHandler,
		onError: deleteErrorHandler
	});

	const deleteRecordHandler = () => {
		deleteRecord({}) // pass row here
		closeConfirmationModal();
	}

	const openConfirmationModal = () => {
		modalInstanceRef.current.open();
	};
	const closeConfirmationModal = () => {
		modalInstanceRef.current.close();
	};

	return (
		<>
			<IconButton
				className="dds__button dds__button--secondary"
				data-for="deleteTicketTip"
				data-tip="Delete"
				style={{ marginTop: 0, marginBottom: 0 }}
				onClick={openConfirmationModal}
				disabled={isDeleting}
				aria-label={`Delete Measures`}
			>
				{isDeleting && <LoadingSpinner size="md" />}
				{!isDeleting && (
					<i
						style={{ fontSize: "1.1rem", color: "red" }}
						className="dds__icon dds__icon--trash"
						aria-hidden="true"
					/>
				)}
				<ReactTooltip id="deleteTicketTip" />
			</IconButton>
			<DDSModal
				themeColor={"black"}
				iconClass={"dds__icon--trash"}
				modalInstanceRef={modalInstanceRef}
				classes={"dds__rounded"}
				id={"infoModal"}
				title={"Confirm Deletion"}
				footerButtons={[
					{
						class: "dds__button--secondary",
						text: "Cancel",
						handleFooterButtonClick: closeConfirmationModal,
					},
					{
						class: "dds__button--destructive",
						text: "Confirm Delete",
						handleFooterButtonClick: deleteRecordHandler,
					},
				]}
			>
				<div
					style={{
						color: "var(--gray-800)",
						margin:"1rem",
						marginTop:"0",
						padding:"1rem",
					}}
				>
					Are you sure you want to remove {row.values.deviceName} ({row.values.financialYear}), device type: {row.values.deviceType}, persona: {row.values.persona}. This action can not be
					undone.
				</div>
			</DDSModal>
		</>
	);
};
export default DeleteAction;