import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const steps = [
    { name: 'Utilization Rules', path: '/assetinventory/ipss/utilizationrules', summary: '' },
    { name: 'Catalog', path: '/assetinventory/ipss/catalog', summary: '' },
    { name: 'Persona Job Family Mapping', path: '/assetinventory/ipss/personajobfamilymapping', summary: '' },
    { name: 'Features with Weightage', path: '/assetinventory/ipss/featureswithweightage', summary: '' },
    { name: 'Catalog Model Train Weightage', path: '/assetinventory/ipss/inventorymodeltrainweightage', summary: '' },
    { name: 'Model Configs', path: '/assetinventory/ipss/modelconfigs', summary: '' },
    { name: 'Graphics Ranking', path: '/assetinventory/ipss/graphicsprocessorranking', summary: '' },
    { name: 'Display Ranking', path: '/assetinventory/ipss/displayranking', summary: '' },
    { name: 'Device Type Ranking', path: '/assetinventory/ipss/devicetyperanking', summary: '' },
    { name: 'Processor Ranking', path: '/assetinventory/ipss/inventoryprocessorranking', summary: '' },
    { name: 'Run Model Page', path: '/assetinventory/ipss/runmodelpage', summary: '' },
];

const ProgressBar: React.FC = () => {
    const location = useLocation();
    const history = useHistory();

    const handleNavigation = (path: string) => {
        history.push(path);
    };

    return (
        <ol className="dds__progress-tracker dds__progress-tracker--sm">
            {steps.map((step, index) => {
                const isComplete = location.pathname !== step.path && steps.findIndex(s => s.path === location.pathname) > index;
                const isActive = location.pathname === step.path;
                const isInProgress = !isComplete && !isActive && steps.findIndex(s => s.path === location.pathname) === index + 1;

                return (

                    <>
                        <li
                            key={step.path}
                            className={`dds__progress-tracker__item 
                            ${isComplete ? 'dds__progress-tracker--complete' : ''}
                            ${location.pathname === step.path ? 'dds__progress-tracker--in-progress' : 'dds__progress-tracker--current'}`
                            }
                            aria-current={isActive ? 'true' : undefined}
                            onClick={() => handleNavigation(step.path)}
                        >
                            <span className="dds__sr-only">{isComplete ? 'complete' : isActive ? 'active' : 'inactive'}</span>
                            <span className="dds__progress-tracker__circle">
                                <span className="dds__progress-tracker__icon"></span>
                            </span>
                            <div className="dds__progress-tracker__content">
                                {isActive ? (
                                    <span className="dds__progress-tracker__step-name">{step.name}</span>
                                ) : (
                                    <a href="#" onClick={(e) => { e.preventDefault(); handleNavigation(step.path); }}>{step.name}</a>
                                )}
                                <span>{step.summary}</span>
                            </div>
                        </li>
                    </>
                );
            })}
        </ol>
    );
};

export default ProgressBar;
