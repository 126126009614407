import React, { useState } from 'react';
import UseNumberValidation from '../../../../ValidationRules/UseNumbervalidation';

const LowThreshold: React.FC<{
    mode: 'new' | 'edit',
    LowThresholdInput: string,
    setLowThresholdInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    LowThresholdInput,
    setLowThresholdInput,
    initialize = false,
}) => {
        // Using the validation hook
        const { isValid, errorMessage, validateInput, setIsValid } = UseNumberValidation(setLowThresholdInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handles input blur to format the value to 2 decimal places
        const handleBlur = () => {
            setIsFocused(false);
            if (LowThresholdInput) {
                const roundedValue = LowThresholdInput.includes('.') ? parseFloat(LowThresholdInput).toFixed(2) : LowThresholdInput;;
                setLowThresholdInput(roundedValue);
            }
        };

        const handleFocus = () => {
            setIsFocused(true);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="LowThreshold-field" className="dds__label">
                    {mode === 'edit' ? 'LowThreshold' : 'Low Threshold'}
                    <span style={{ color: 'red' }}> *</span>
                </label>
                <div className="dds__input-text__wrapper">
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: "100%",
                            borderRadius: "1.5rem",
                            outline: 'none'
                        }}
                        maxLength={100}
                        name="LowThreshold-field"
                        id="LowThreshold-field"
                        required
                        aria-labelledby="LowThreshold-label"
                        onChange={(event) => validateInput(event.target.value)}
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={LowThresholdInput}
                    />
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            Accepts a valid number. {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && LowThresholdInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Accepts a valid number (up to 2 decimal places).
                        </span>
                    )}
                </div>
            </div>
        );
    }

export default LowThreshold;
