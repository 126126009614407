import React, { useState } from 'react';
import UseLongTextValidation from '../../../../ValidationRules/UseLongTextValidation'; // Import the validation component

const FeatureDescription: React.FC<{
  mode: 'new' | 'edit',
  FeatureDescriptionInput: string,
  setFeatureDescriptionInput: React.Dispatch<React.SetStateAction<string>>,
  initialize?: boolean
}> = ({
  mode,
  FeatureDescriptionInput,
  setFeatureDescriptionInput,
  initialize = false,
}) => {
    // Use the text validation hook
    const { isValid, errorMessage, validateInput, setIsValid } = UseLongTextValidation(setFeatureDescriptionInput);
    const [isFocused, setIsFocused] = useState(false);

    // Handle focus to show validation state
    const handleFocus = () => {
      setIsFocused(true);
    };

    // Handle blur event
    const handleBlur = () => {
      setIsFocused(false);
    };

    return (
      <div className="dds__input-text__container">
        <label htmlFor="FeatureDescription-field" className="dds__label">
          {mode === 'edit' ? 'FeatureDescription' : 'Feature Description'}
        </label>
        <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
          <input
            type="text"
            className="dds__input-text"
            style={{
              width: '100%',
              height: '5rem',
              borderRadius: '1.5rem'
            }}
            name="FeatureDescription-field"
            id="FeatureDescription-field"
            required
            aria-labelledby="FeatureDescription-label"
            onChange={(event) => validateInput(event.target.value)} // Use validateInput from UseLongTextValidation
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={FeatureDescriptionInput}
          />
          {/* Show validation messages based on focus and validity */}
          {isFocused && !isValid && (
            <span style={{ color: 'red', fontSize: '12px' }}>
              Accepts a valid string. {errorMessage}
            </span>
          )}
          {isFocused && isValid && FeatureDescriptionInput && (
            <span style={{ color: 'green', fontSize: '12px' }}>
              Accepts a valid string (up to 300 characters).
            </span>
          )}
        </div>
      </div>
    );
  };

export default FeatureDescription;
