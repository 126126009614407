import React, { useState } from 'react';
import UseTextValidation from '../../../../ValidationRules/UseTextValidation'; // Import the validation component

const DeviceType: React.FC<{
    mode: 'new' | 'edit';
    DeviceTypeInput: string;
    setDeviceTypeInput: React.Dispatch<React.SetStateAction<string>>;
    initialize?: boolean;
}> = ({ mode, DeviceTypeInput, setDeviceTypeInput, initialize = false }) => {

    // Use the text validation hook
    const { isValid, errorMessage, validateInput, setIsValid } = UseTextValidation(setDeviceTypeInput);
    const [isFocused, setIsFocused] = useState(false);

    // Handle focus to show validation state
    const handleFocus = () => {
        setIsFocused(true);
    };

    // Handle blur event
    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className="dds__input-text__container">
            <label htmlFor="DeviceType-field" className="dds__label">
                Device Type
                <span style={{ color: 'red' }}> *</span>
            </label>
            <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                <input
                    type="text"
                    className="dds__input-text"
                    style={{
                        width: '100%',
                        borderRadius: '1.5rem',
                    }}
                    placeholder=""
                    maxLength={100}
                    name="DeviceType-field"
                    id="DeviceType-field"
                    required
                    aria-labelledby="DeviceType-label"
                    onChange={(event) => validateInput(event.target.value)}  // Use validateInput from UseTextValidation
                    onBlur={handleBlur} // Add onBlur handler
                    onFocus={handleFocus} // Add onFocus handler
                    value={DeviceTypeInput}
                />
                {/* Show validation messages based on focus and validity */}
                {isFocused && !isValid && (
                    <span style={{ color: 'red', fontSize: '12px' }}>
                        Accepts a valid string. {errorMessage}
                    </span>
                )}
                {isFocused && isValid && DeviceTypeInput && (
                    <span style={{ color: 'green', fontSize: '12px' }}>
                        Accepts a valid string (up to 30 characters).
                    </span>
                )}
            </div>
        </div>
    );
};

export default DeviceType;
