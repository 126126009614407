import React, { useState } from 'react';
import UseIntegerValidation from '../../../../ValidationRules/UseIntegerValidation'; // Import integer validation module

const Ranking: React.FC<{
    mode: 'new' | 'edit';
    RankingInput: string;
    setRankingInput: React.Dispatch<React.SetStateAction<string>>;
    initialize?: boolean;
}> = ({
    mode,
    RankingInput,
    setRankingInput,
    initialize = false,
}) => {
        // Use the integer validation hook
        const { isValid, errorMessage, validateInput } = UseIntegerValidation(setRankingInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handle ranking input change
        const onRankingInputChange = (event) => {
            event.preventDefault();
            validateInput(event.target.value); // Validate input
        };

        // Handle focus and blur events for validation feedback
        const handleFocus = () => {
            setIsFocused(true);
        };

        const handleBlur = () => {
            setIsFocused(false);
            // Optionally round or normalize input here if needed
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="Ranking-field" className="dds__label">
                    Ranking
                    {mode === 'new' ? <span style={{ color: 'red' }}> *</span> : ""}
                </label>
                <div className="dds__input-text__wrapper">
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: "100%",
                            borderRadius: "1.5rem"
                        }}
                        name="Ranking-field"
                        id="Ranking-field"
                        required
                        aria-labelledby="Ranking-label"
                        onChange={onRankingInputChange} // Validate on change
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={RankingInput}
                    />
                    {/* Show validation messages based on focus and validity */}
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errorMessage} {/* Display error message */}
                        </span>
                    )}
                    {isFocused && isValid && RankingInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Valid integer input. {/* Success message */}
                        </span>
                    )}
                </div>
            </div>
        );
    };

export default Ranking;
