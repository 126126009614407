import { useState, useEffect } from 'react';

const Use0And1Validation = (setInputValue: React.Dispatch<React.SetStateAction<string>>) => {
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const validateInput = (value: string) => {
        const trimmedValue = value.trim();
        if (trimmedValue === '0' || trimmedValue === '1') {
            setIsValid(true);
            setErrorMessage('');
            setInputValue(trimmedValue);
        } else {
            setIsValid(false);
            setErrorMessage('Input must be either 0 or 1');
            setInputValue('');
        }
    };

    useEffect(() => {
        // Reset the validity when input value is changed directly
        setIsValid(true);
        setErrorMessage('');
    }, [setInputValue]); // Remove unnecessary check

    return { isValid, errorMessage, validateInput, setIsValid };
};

export default Use0And1Validation;
