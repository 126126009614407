import ActionColumn from "./ActionColumn/ActionColumn";

const columns: any[] = [

	{ Header: "Telemetry Measures", accessor: "telemetry_measures" },
	{ Header: "Sub Measure", accessor: "sub_measure", disableSortBy: true },
	{ Header: "Low Threshold (%)", accessor: "low_threshold", disableSortBy: false, width: 20 },
	{ Header: "Medium Threshold (%)", accessor: "medium_threshold", disableSortBy: true, width: 20 },
	{ Header: "High Threshold (%)", accessor: "high_threshold", disableSortBy: false, width: 20 },
	{
		Header: "Action",
		accessor: "action",
		disableSortBy: true,
		Cell: ActionColumn,
	},
	// {
	// 	Header: () => null,
	// 	accessor: "measure_Values",
	// 	disableSortBy: true,
	// 	show: false, // Set width to 0 or null to hide it visually
	// 	disableFilters: true,

	// },
	{
		// Header: () => null,
		Header: "id",
		accessor: "id",
		disableSortBy: true,
		show: false, // Set width to 0 or null to hide it visually

	},
	// { Header:  () => null, accessor: "measureValues",disableSortBy:true,width:null},
	// // More columns...
];

export default columns;
