import React, { useState } from 'react';
import UseYNValidation from '../../../../ValidationRules/UseYNValidation'; // Import the validation component

const Connectivity: React.FC<{
    mode: 'new' | 'edit',
    ConnectivityInput: string,
    setConnectivityInput: React.Dispatch<React.SetStateAction<string>>,
    initialize?: boolean
}> = ({
    mode,
    ConnectivityInput,
    setConnectivityInput,
    initialize = false,
}) => {
        // Use the Y/N validation hook
        const { isValid, errorMessage, validateInput, finalizeInput, setIsValid } = UseYNValidation(setConnectivityInput);
        const [isFocused, setIsFocused] = useState(false);

        // Handle focus to show validation state
        const handleFocus = () => {
            setIsFocused(true);
        };

        // Handle blur event
        const handleBlur = () => {
            setIsFocused(false);
            finalizeInput(ConnectivityInput);
        };

        return (
            <div className="dds__input-text__container">
                <label htmlFor="Connectivity-field" className="dds__label">
                    {mode === 'edit' ? 'Connectivity' : 'Connectivity'}
                </label>
                <div className="dds__input-text__wrapper" style={{ position: 'relative' }}>
                    <input
                        type="text"
                        className="dds__input-text"
                        style={{
                            width: '100%',
                            borderRadius: '1.5rem',
                        }}
                        placeholder=""
                        name="Connectivity-field"
                        id="Connectivity-field"
                        required
                        aria-labelledby="Connectivity-label"
                        onChange={(event) => validateInput(event.target.value)} // Use validateInput from UseYNValidation
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        value={ConnectivityInput}
                    />
                    {/* Show validation messages based on focus and validity */}
                    {isFocused && !isValid && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            Invalid input. {errorMessage}
                        </span>
                    )}
                    {isFocused && isValid && ConnectivityInput && (
                        <span style={{ color: 'green', fontSize: '12px' }}>
                            Enter either Yes or No
                        </span>
                    )}
                </div>
            </div>
        );
    };

export default Connectivity;
